import React, { useEffect, useRef } from "react";

const useDragger = (headerId: string, draggableId: string, position: any, setPosition: any) => {
  const isDragging = useRef(false);
  const offset = useRef({ x: 0, y: 0 });

  useEffect(() => {

    document.body.style.overflow = "hidden";

    const header = document.getElementById(headerId);
    const draggable = document.getElementById(draggableId);

    if (!header || !draggable) return;

    const onMouseDown = (e: MouseEvent) => {
      isDragging.current = true;
      const rect = draggable.getBoundingClientRect();
      offset.current = {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      };
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e: MouseEvent) => {
      if (!isDragging.current) return;

      const parentRect = draggable.parentElement!.getBoundingClientRect();
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const targetWidth = draggable.offsetWidth;
      const targetHeight = draggable.offsetHeight;

      let nextX = e.clientX - parentRect.left - offset.current.x;
      let nextY = e.clientY - parentRect.top - offset.current.y;

      const maxLeft = 0 - targetWidth * 0.47;
      const maxRight = screenWidth - targetWidth * 0.47;
      const maxTop = 0;
      const maxBottom = screenHeight - targetHeight * 0.2;

      nextX = Math.min(Math.max(nextX, maxLeft), maxRight);
      nextY = Math.min(Math.max(nextY, maxTop), maxBottom);

      setPosition({ top: `${nextY}px`, left: `${nextX}px` });
    };

    const onMouseUp = () => {
      isDragging.current = false;
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    header.addEventListener("mousedown", onMouseDown);

    return () => {
      header.removeEventListener("mousedown", onMouseDown);
    };
  }, [headerId, draggableId, position, setPosition]);

};

export default useDragger;
