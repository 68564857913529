import { useState } from "react";
import { getItem } from "../../utils/token";
import SignOutIcon from "../../assets/Images/SignOutIcon.svg";
import ProfileIcon from "../../assets/Images/ProfileIcon.svg";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { BiLock } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useLazyLogoutUserQuery } from "../../redux/API/Login/loginApi";
import { openDialog } from "../../redux/Slices/dialogSlice";
import { useDispatch } from "react-redux";
import { useAssociatedetailsQuery } from "../../redux/API/ProfileSetup/profileSetupApi";
import { resetUserDataForm } from "../../redux/Slices/profileSetupSlice";
import { setIsTestStarted } from "../../redux/Slices/startFullTestSlice";
import { setIsTestInstructionsOpen } from "../../redux/Slices/startFullTestSlice";

const Header = () => {
  const [dropdown, setDropdown] = useState(false);
  const userName = getItem("userName");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logoutUser] = useLazyLogoutUserQuery();
  const isStudent = getItem("userRole") === "STUDENT";
  const handleSignOut = async () => {
    await logoutUser({});
    navigate("/login");
    localStorage.clear();
    dispatch(setIsTestStarted(false));
    dispatch(setIsTestInstructionsOpen(false));
  };
  const handleHeaderClick = () => {
    getItem("userRole") === "STUDENT"
      ? navigate("/dashboard")
      : navigate("/parentdashboard");
  };
  const handleOpenDialog = (dialog: string) => {
    dispatch(openDialog(dialog));
    setDropdown(false);
  };

  const handleClickAddParentOrStudentDetails = () => {
    if (isStudent) {
      dispatch(resetUserDataForm());
      handleOpenDialog("addParentDetails");
    } else {
      handleOpenDialog("addStudentDetails");
    }
  };

  const { data } = useAssociatedetailsQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  return (
    <header className="w-full h-[72px] py-[17px] shadow-header-shadow">
      <div className="w-[70%] mx-auto flex justify-between ">
        <button onClick={handleHeaderClick}>
          <h1 className="font-bold font-gully text-[#283044] text-center text-3xl font-pretendard cursor-pointer">
            Fullmarks<span className="text-[50px]">.</span> <span className="text-appPrimaryColor">SATprep</span>
          </h1>
        </button>
        <div className="flex items-center relative gap-3">
          <p className="font-gully text-[21px] font-medium">{userName ?? ""}</p>
          <button onClick={() => setDropdown(!dropdown)}>
            <img
              src={ProfileIcon}
              alt="profile"
              className="w-[42px] h-[42px] rounded-[42px]"
            />
          </button>
          {dropdown && (
            <div className="flex flex-col items-start z-20 w-[248px] absolute top-14 right-0 bg-white rounded-xl shadow-profile-nav">
              <button
                className="flex w-full h-12 px-[14px] py-[18px] items-center gap-3 hover:bg-lightGrey hover:rounded-t-xl"
                onClick={() => handleOpenDialog("editProfile")}
              >
                <FaRegEdit className="w-6 h-6 text-black" />
                <p className="font-gully text-base font-medium">Edit Profile</p>
              </button>
              {isStudent && !data?.data && (
                <button
                  className="flex w-full h-12 px-[14px] py-[18px] items-center gap-3 hover:bg-lightGrey"
                  onClick={handleClickAddParentOrStudentDetails}
                >
                  <MdOutlineLibraryAdd className="w-6 h-6 text-black" />
                  <p className="font-gully text-base font-medium">
                    {isStudent ? "Add Parent Details" : "Add Student Details"}
                  </p>
                </button>
              )}
              <button
                className="flex w-full h-12 px-[14px] py-[18px] items-center gap-3 hover:bg-lightGrey"
                onClick={() => handleOpenDialog("changePassword")}
              >
                <BiLock className="w-6 h-6 text-black" />
                <p className="font-gully text-base font-medium">
                  Change Password
                </p>
              </button>
              <button
                className="flex w-full h-12 px-[14px] py-[18px] items-center gap-3 hover:bg-lightGrey hover:rounded-b-xl"
                onClick={handleSignOut}
              >
                <img src={SignOutIcon} className="w-6 h-6" alt="sign-out" />
                <p className="font-gully text-base font-medium">Sign Out</p>
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
