import api from "../createApi";
import { TestResult } from "./userTestsType";

export const startTestApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllMockTests: builder.query({
      query: () => ({
        url: "/test/getfreetest",
        method: "GET",
      }),
    }),
    getProTests: builder.query({
      query: () => ({
        url: "/test/getprotest",
        method: "GET",
      }),
    }),
    getPricingPlansData: builder.query({
      query: () => ({
        url: "/plan/getAll",
        method: "GET",
      }),
    }),
    getStartTestData: builder.query({
      query: (testId) => ({
        url: `/test/starttest?testId=${testId}`,
        method: "GET",
      }),
    }),
    getQuestionBankList: builder.query({
      query: () => ({
        url: "/questionbank/getall",
        method: "GET",
      }),
    }),
    getQuestionInQuestionBank: builder.query({
      query: (questionBankId) => ({
        url: `/questionbank/getbyid?questionBankId=${questionBankId}`,
        method: "GET",
      }),
    }),
    saveAndExitTest: builder.mutation({
      query: (payload) => ({
        url: "/test/savetest",
        method: "POST",
        body: payload,
      }),
    }),
    saveAndExitQuestionBank: builder.mutation({
      query: (payload) => ({
        url: "/questionbank/savequestionbank",
        method: "POST",
        body: payload,
      }),
    }),
    endTest: builder.mutation({
      query: (testId) => ({
        url: `/test/endtest?userTestId=${testId}`,
        method: "PUT",
      }),
    }),
    getAllCompletedTest: builder.query({
      query: (id) => ({
        url: id ? `/test/allusertests?userId=${id}` : "/test/allusertests",
        method: "GET",
      }),
    }),
    scaledScores: builder.mutation({
      query: ({ testId, payLoad }) => ({
        url: `test/calculate/scaledscore`,
        method: "PUT",
        body: payLoad,
        params: { testId },
      }),
    }),
    retrieveTest: builder.query({
      query: (testId) => ({
        url: `test/retrievetest?testId=${testId}`,
        method: "GET",
      }),
    }),
    userTestActivity: builder.mutation({
      query: (payload) => ({
        url: "usertest/addactivity",
        method: "POST",
        body: payload,
      }),
    }),
    userProcessActivity: builder.mutation({
      query: (userTestId) => ({
        url: "usertest/processactivity",
        method: "PUT",
        params: { userTestId },
      }),
    }),
    getStartTestDataV1: builder.query({
      query: (isPreviewTest) => ({
        url: `/test/starttests/v1`,
        method: "GET",
        params: { isPreviewTest },
      }),
    }),
    discardTest: builder.mutation({
      query: (payload) => ({
        url: `/test/discard/test`,
        method: "POST",
        body: payload,
      }),
    }),
    resumeTest: builder.query({
      query: () => ({
        url: `test/getresumetest`,
        method: "GET",
      }),
    }),
    startPreviewTest: builder.query({
      query: (testId) => ({
        url: "test/startpreviewtest",
        method: "GET",
        params: { testId },
      }),
    }),
    getPreviewTest: builder.query({
      query: () => ({
        url: "test/getpreviewtest",
        method: "GET",
      }),
    }),
    getStudentInfo: builder.query({
      query: (email) => ({
        url: `user/getStudentParentInfo?email=${email}`,
        method: "GET",
      }),
    }),
    getParentStudentInfo: builder.query({
      query: () => ({
        url: `user/parent/associatedstudents`,
        method: "GET",
      }),
    }),
    getDiscardInfoOptions: builder.query({
      query: () => ({
        url: `discard/getall`,
        method: "GET",
      }),
    }),
    getUserResults: builder.query({
      query: (userId) => {
        const params: { userId?: string } = {};
        if (userId) {
          params.userId = userId;
        }
        return {
          url: `test/userresults`,
          method: "GET",
          params,
        };
      },
    }),
    getUserResultsById: builder.query<
      TestResult,
      { userId?: string; testId: string }
    >({
      query: ({ userId, testId }) => {
        const params: { userId?: string; testId: string } = { testId };
        if (userId) {
          params.userId = userId;
        }
        let url = "";
        if (userId) {
          url = `test/userresult/byid?userId=${userId}&testId=${testId}`;
        } else {
          url = `test/userresult/byid?testId=${testId}`;
        }
        return {
          url,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useUserTestActivityMutation,
  useLazyGetAllMockTestsQuery,
  useGetAllMockTestsQuery,
  useLazyGetProTestsQuery,
  useLazyGetStartTestDataQuery,
  useSaveAndExitTestMutation,
  useEndTestMutation,
  useGetAllCompletedTestQuery,
  useLazyGetAllCompletedTestQuery,
  useLazyRetrieveTestQuery,
  useGetPricingPlansDataQuery,
  useGetQuestionBankListQuery,
  useGetQuestionInQuestionBankQuery,
  useScaledScoresMutation,
  useSaveAndExitQuestionBankMutation,
  useDiscardTestMutation,
  useLazyResumeTestQuery,
  useResumeTestQuery,
  useLazyGetStartTestDataV1Query,
  useGetStartTestDataV1Query,
  useLazyStartPreviewTestQuery,
  useGetPreviewTestQuery,
  useGetStudentInfoQuery,
  useGetParentStudentInfoQuery,
  useGetDiscardInfoOptionsQuery,
  useGetUserResultsQuery,
  useLazyGetUserResultsQuery,
  useGetUserResultsByIdQuery,
  useUserProcessActivityMutation,
} = startTestApi;
