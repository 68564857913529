import { LiaCalculatorSolid } from "react-icons/lia";
export const InAppCalculatorDescription = () =>(
      <p>
        <span className="font-bold"> 
          <LiaCalculatorSolid className="inline-flex items-center flex-wrap" />
          Calculator:
        </span>
         On math questions, you will have access to a graphing calculator built
        into the app. You can also use your own approved calculator
      </p>
  );
    
    export default InAppCalculatorDescription;