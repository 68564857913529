import { VscBookmark } from "react-icons/vsc";
export const MarkForReviewDescription = () => (
    <>
      <p>
        <span className="font-bold">
          <VscBookmark className="inline-flex items-center flex-wrap" />
          Mark for Review:
        </span>
        Flag any question you want to come back to later.
      </p>
    </>
  );
    
    export default MarkForReviewDescription;