import { ImSpinner6 } from "react-icons/im";

const CustomButton = ({
  name,
  onClickHandler,
  width,
  font = "font-medium",
  bg = "bg-primaryColor",
  color = "white",
  round = "md",
  text = "text-base",
  py = "py-2",
  border = "border-transparent",
  hover,
  mx = "mx-0",
  disabled,
  disable = false,
  loader = false,
}: {
  name: string;
  onClickHandler: () => void;
  width?: string;
  font?: string;
  bg?: string;
  color?: string;
  round?: string;
  text?: string;
  py?: string;
  border?: string;
  hover?: string;
  mx?: string;
  disabled?: boolean;
  disable?: boolean;
  loader?: boolean;
}) => {
  return (
    <>
      {/*if u want to  use focus use like focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2  */}
      <button
        type="button"
        onClick={onClickHandler}
        className={`box-border inline-flex ${width}  ${font} justify-center border 
        ${border} ${bg} px-4 ${py} ${color} ${text} focus:outline-none  text-${color} ${hover}
        ${disabled && "disabled:opacity-50 disabled:pointer-events-none"} 
        ${disable && "cursor-none"} rounded-${round} ${mx} ${disabled && "bg-secondaryColor"}`}
        disabled={disabled || disable}
      >
        <div className="flex justify-center items-center gap-x-3">
          {name}{" "}
          {loader && (
            <ImSpinner6 className="animate-spin text-base text-white ml-2" />
          )}
        </div>
      </button>
    </>
  );
};

export default CustomButton;
