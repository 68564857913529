import { useCallback, useEffect, useState } from "react";
import CommonTestModuleLayout from "../ReUsableComponents/CommonTestModuleLayout";
import CustomButton from "../ReUsableComponents/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCurrentModuleName,
  setCount,
  setCurrentTime,
  setIsHighlightNotes,
  setIsModuleCompleted,
  setIsPopUp,
  setQuestionId,
  setReviewPageClicked,
  setSpentTime,
  setTestCompleted,
} from "../../redux/Slices/startFullTestSlice";

import { selectFullTestState } from "../../redux/Selectors/selectorsUse";
import { RootState } from "../../redux/Store/store";
import { getItem } from "../../utils/token";
import useSendTestActivity, { ActivityData } from "../customHook/useUserTestActivity";

const WriteTestModuleOne = () => {
  const dispatch = useDispatch();
  const { startTestSlice, resultSlice } = useSelector(selectFullTestState);
  const saveHandler = () => {
    // dispatch(openDialog("writingInstructions"));
  };
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const sendTestActivity = useSendTestActivity();

  const { readingModuleOneQuestions, count, reviewPageClicked } =
    startTestSlice;
  const { currentTime, userTestId } = useSelector(
    (state: RootState) => state.root.startFullTest
  );
 
  const { writingModuleOneAnswers } = resultSlice;
  const isPreview = getItem('testType') === 'PREVIEW';
  const heading = useCallback(() => {
    return (
      <div className="flex flex-col">
        <div className="text-lg font-semibold">
          {isPreview ? (
            "Section 1: Reading and Writing Questions"
          ) : (
            <>
              <p>Section 1,Module 1: Reading and Writing</p>
              <p className="text-center">Questions</p>
            </>
          )}
        </div>
      </div>
    );
  }, []);
  const timer = () => {
    return <div>29:25</div>;
  };
  const exit = () => {
    return (
      <div>
        <CustomButton
          name="Save & Exit"
          onClickHandler={saveHandler}
          bg="bg-white"
          color="[#333]"
          font="font-bold"
          width="w-full"
          border="border-[#333]"
          py="py-2"
          round="full"
        />
      </div>
    );
  };
  const children = () => {
    return (
      <div className="flex justify-center items-center">
        <div>Writing Test Module One Children Component </div>
        <div></div>
      </div>
    );
  };
  const textName = () => {
    return <div className="font-semibold"> {getItem("currentTestName")} </div>;
  };
  let moduleCompleteTimeout: NodeJS.Timeout | null = null;
  let writingModule2Timeout: NodeJS.Timeout | null = null;

  useEffect(() => {
    setStartTime(new Date());
  }, [count]);
  const nexxtClickHandler = async () => {
    if (!startTime || isNextDisabled) return;
    setIsNextDisabled(true);
    const endTime = new Date();
    dispatch(setReviewPageClicked(false));
    dispatch(setIsPopUp(false));
    if (count > readingModuleOneQuestions.length - 1 || reviewPageClicked) {
      dispatch(
        setSpentTime({
          field: "readingAndWritingModuleOneSpentTime",
          value: +currentTime,
        })
      );
      dispatch(setIsModuleCompleted(true));
      moduleCompleteTimeout = setTimeout(() => {
        dispatch(changeCurrentModuleName("moduleComplete"));
      }, 500);
      writingModule2Timeout = setTimeout(() => {
        if (getItem("testType") === "PREVIEW") {
          dispatch(changeCurrentModuleName("mathModule1"));
          dispatch(setCurrentTime(0));
        } else {
          dispatch(changeCurrentModuleName("writingModule2"));
          dispatch(setCurrentTime(1920));
        }
      }, 5000);
      dispatch(setCount(""));
    } else {
      setStartTime(endTime);
      dispatch(setCount("Increment"));
      if(!isPreview){
      const activityData: ActivityData = {
        testId: userTestId,
        startTime,
        endTime,
        module: 301,
        section: 702,
        fromQuestion: count + 1,
        toQuestion:
          count + 1 === readingModuleOneQuestions.length
            ? count + 1
            : count + 2,
        event: 501,
        questionId: readingModuleOneQuestions[count]._id,
      }; 
      dispatch(setQuestionId(readingModuleOneQuestions[count]._id));
      sendTestActivity(activityData);
    }
    }
    setTimeout(() => setIsNextDisabled(false), 500);
  };

  useEffect(() => {
    // Clear timeouts on component unmount
    dispatch(setIsHighlightNotes(false)); //make it false when exma is started

    return () => {
      dispatch(setIsModuleCompleted(false));
      if (moduleCompleteTimeout) clearTimeout(moduleCompleteTimeout);
      if (writingModule2Timeout) clearTimeout(writingModule2Timeout);
    };
  }, []);

  const next = () => {
    return (
      <div>
        <CustomButton
          name="Next"
          onClickHandler={nexxtClickHandler}
          font="font-bold"
          text="text-sm"
          py="py-2 px-5"
          round="full"
          // hover="hover:bg-primaryColor"
        />
      </div>
    );
  };

  useEffect(() => {
    dispatch(changeCurrentModuleName("writingModule1"));
    // // dispatch(openDialog("writingInstructions"));
    dispatch(setTestCompleted(false));
    dispatch(setIsModuleCompleted(false));
    dispatch(setIsModuleCompleted(false));
  }, []);

  useEffect(() => {
    if (currentTime <= 0 && getItem("testType") !== "PREVIEW") {
      dispatch(setIsModuleCompleted(true));
      moduleCompleteTimeout = setTimeout(() => {
        dispatch(changeCurrentModuleName("moduleComplete"));
      }, 500);
      writingModule2Timeout = setTimeout(() => {
        dispatch(changeCurrentModuleName("writingModule2"));
        dispatch(setCurrentTime(1920));
      }, 7000);
      dispatch(setCount(""));
    }
  }, [currentTime, dispatch]);
  return (
    <CommonTestModuleLayout
      heading={heading}
      timer={timer}
      exit={exit}
      textName={textName}
      next={next}
      questionsList={readingModuleOneQuestions}
      moduleName={"writingModuleOneAnswers"}
      userAnswers={writingModuleOneAnswers}
      setStartTime={setStartTime}
      startTime={startTime}
      module={301}
      section={702}
      headerHeading={"Section 1, Module 1: Reading and Writing Questions"}
    >
      {children}
    </CommonTestModuleLayout>
  );
};

export default WriteTestModuleOne;
