

export const AssistiveTechnologyDescription = () => (
  <>
    <p>
      Any approved assistive technology that you use when you're online should still work during your exam. If you use assistive technologies (AT), such as screen readers (JAWS, NVDA, VoiceOver, ChromeVox, ZoomText Fusion, etc.) to interact with a standard web browser, the same commands can be used to navigate through content in the digital exam. The exception to this would be if you use a web-based assistive technology or a browser extension.
    </p>
    <p className="my-6">
      If you use assistive technology, open/start the AT device or software before you open Bluebook for practice and on exam day. On exam day, adjust any necessary settings before you start to check in for the digital exam. Exceptions are noted when configuration is not required ahead of the exam.
    </p>
  </>
);

export default AssistiveTechnologyDescription;
