import { useState } from "react";
import { IoMdArrowDropup, IoMdArrowDropdown, IoMdClose } from "react-icons/io";
import { BsArrowsAngleContract, BsArrowsAngleExpand } from "react-icons/bs";
import useDragger from "../customHook/useDragger";
import { PiDotsSixBold } from "react-icons/pi";

interface LineReaderProps{
  handhandleLineReader: () => void;
  module:string;
}

const LineReader = ({ handleLineReader,module }: any) => {
  let intialMaximizedState = module === "mathsModuleOneAnswers" || module === "mathsModuleTwoAnswers";
  const [transparencyLevel, setTransparencyLevel] = useState<number>(1);
  const [mode, setMode] = useState("increasing");
  const [isMaximized, setIsMaximized] = useState<boolean>(intialMaximizedState);
  const [isDragging, setIsDragging] = useState(false);
  const [intialMessage, setIntialMessage] = useState(true);
  const [position, setPosition] = useState({ top: "74px", left: "0px" });

  useDragger("lineHeader", "draggableCard", position, setPosition); // Pass position and setPosition to the hook

  const handleTransparencyLevel = () => {
    setTransparencyLevel((prevLevel) => {
      if (mode === "increasing") {
        if (prevLevel === 3) {
          setMode("decreasing");
          return 2;
        }
        return prevLevel + 1;
      } else {
        if (prevLevel === 1) {
          setMode("increasing");
          return 2;
        }
        return prevLevel - 1;
      }
    });
  };

  const getArrowIcon = () => {
    if (
      transparencyLevel === 3 ||
      (transparencyLevel === 2 && mode === "decreasing")
    )
      return <IoMdArrowDropup className="w-7 h-7 text-slate-600" />;
    if (
      transparencyLevel === 1 ||
      (transparencyLevel === 2 && mode === "increasing")
    )
      return <IoMdArrowDropdown className="w-7 h-7 text-slate-600" />;
  };

  const handleMaximize = () => {
    setIsMaximized(!isMaximized);
    setPosition({ top: "74px", left: "0px" }); // Reset position on maximize
  };

  const renderTransparency = () => {
    if (transparencyLevel === 2) {
      return (
        <>
          <div className="flex justify-between">
            <div className="h-6 bg-black w-[5%] opacity-80"></div>
            <div className="h-6 bg-black w-[5%] opacity-80"></div>
          </div>
          <div className="h-8 bg-black opacity-80"></div>
        </>
      );
    }
    if (transparencyLevel === 3) {
      return (
        <>
          <div className="flex justify-between">
            <div className="h-12 bg-black w-[5%] opacity-80"></div>
            <div className="h-12 bg-black w-[5%] opacity-80"></div>
          </div>
          <div className="h-2 bg-black opacity-80"></div>
        </>
      );
    }
    if (transparencyLevel === 1) {
      return <div className="h-14 bg-black opacity-80"></div>;
    }
  };

  return (
    <div
      id="draggableCard"
      className={`absolute ${
        isMaximized ? "w-full" : "w-1/2"
      } shadow-md border rounded-md z-50`}
      style={{ top: position.top, left: position.left }}
    >
      <button
        id="lineHeader"
        className="h-[2.75rem] w-full bg-black opacity-90 flex items-center justify-between px-5 cursor-move"
        onMouseDown={() => {
          setIsDragging(true);
          setIntialMessage(false);
        }}
        onMouseUp={() => setIsDragging(false)}
      >
        <div className="flex-1"></div>

        <div className="flex items-center justify-center flex-1">
          <button
            onClick={() => setIsDragging(true)}
            className="rounded focus:outline-none focus:ring-2 focus:ring-white"
          >
            <PiDotsSixBold
              className={`w-8 h-6 text-white rounded ${
                isDragging ? "bg-slate-500" : "bg-black"
              }`}
            />
          </button>
        </div>

        <div className="flex items-center space-x-7 flex-1 justify-end">
          <button
            onClick={handleMaximize}
            aria-label={isMaximized ? "Restore Window" : "Maximize Window"}
          >
            {isMaximized ? (
              <BsArrowsAngleContract className="w-4 h-4 text-white" />
            ) : (
              <BsArrowsAngleExpand className="w-4 h-4 text-white" />
            )}
          </button>

          <button onClick={handleLineReader} aria-label="Close">
            <IoMdClose className="w-6 h-6 text-white" />
          </button>
        </div>
      </button>

      <div className="bg-transparent h-40 flex flex-col">
        <div className="h-12 bg-black opacity-80 text-white py-2 text-center">
          {intialMessage && (
            <p>
              Click on the six dots, then use the arrow keys to move the line
              reader.
            </p>
          )}
        </div>

        <div className="flex justify-between">
          <div className="h-6 bg-black w-[5%] opacity-80"></div>
          <div className="h-6 bg-black w-[5%] opacity-80"></div>
        </div>

        {renderTransparency()}

        <div className="h-8 bg-black opacity-80 flex justify-center items-center">
          <button
            className="hover:bg-white rounded-md"
            onClick={() => handleTransparencyLevel()}
          >
            {getArrowIcon()}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LineReader;
