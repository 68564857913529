import React, { ChangeEvent, useEffect, useState } from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import AuthButton from "../../pages/LogInPage/LoginButton";
import { closeDialog } from "../../redux/Slices/dialogSlice";
import { useDispatch, useSelector } from "react-redux";
import { BiSave } from "react-icons/bi";
import CustomTextField from "../ReUsableComponents/CustomTextField";
import Dropdown from "../../pages/LogInPage/Dropdown";
import {
  useFetchUserQuery,
  useUpdateExistingUserMutation,
} from "../../redux/API/Login/loginApi";
import {
  useCountyGetAllQuery,
  useLazyGetCountySchoolByIdQuery,
} from "../../redux/API/ProfileSetup/profileSetupApi";
import {
  convertToUSANumber,
  formatPhoneNumberToInternational,
  usePhoneValidation,
} from "../../utils";
import { IoWarningOutline } from "react-icons/io5";
import { GrCircleAlert } from "react-icons/gr";
import { getItem, setItem } from "../../utils/token";
import { RootState } from "../../redux/Store/store";
import { jwtDecode } from "jwt-decode";

type UserDetailsData = {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  role: string;
  county: string;
  school: string;
};

const EditProfile = () => {
  const dispatch = useDispatch();
  const { validatePhone } = usePhoneValidation();

  const {
    data: userData,
    isLoading: userDataLoading,
    isSuccess: userDataSuccess,
    isError: userDataError,
  } = useFetchUserQuery({}, { refetchOnMountOrArgChange: true });
  useCountyGetAllQuery({}, { refetchOnMountOrArgChange: true } );
  const [getCountySchoolById] = useLazyGetCountySchoolByIdQuery();
  const [updateExistingUser] = useUpdateExistingUserMutation();

  const [userDetails, setUserDetails] = useState<UserDetailsData>({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    role: "",
    county: "",
    school: "",
  });
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({
    firstName: false,
    lastName: false,
    email: false,
    mobile: false,
    role: false,
    county: false,
    school: false,
  });
  const [isCountyDropdownOpen, setIsCountyDropdownOpen] = useState(false);
  const [isSchoolDropdownOpen, setIsSchoolDropdownOpen] = useState(false);
  const [schoolData, setSchoolData] = useState();
  const [saveLoading, setSaveLoading] = useState(false);
  const [apiError, setApiError] = useState(false);

  const {countyData} = useSelector((state:RootState) => state.root.profileSetupSlice)

  const handleCloseDialog = () => {
    dispatch(closeDialog());
  };

  const handleDropdownChange = async (val: any, name: string) => {
    if (name === "county" && val?.id !== userDetails.county) {
      await fetchSchool(val?.id);
      setUserDetails((prev) => ({ ...prev, county: val?.id, school: "" }));
      setErrors((prev) => ({ ...prev, school: true }));
    } else if (name === "school") {
      setUserDetails((prev) => ({ ...prev, school: val?.id }));
      setErrors((prev) => ({ ...prev, school: false }));
    }
    setIsCountyDropdownOpen(false);
    setIsSchoolDropdownOpen(false);
  };

  const fetchSchool = async (countyId: string) => {
    if (countyId) {
      const school = await getCountySchoolById(countyId).unwrap();
      setSchoolData(school?.data);
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    let formattedNumber = input;

    if (input.length <= 3) {
      formattedNumber = input; // No formatting for 1-3 digits
    } else if (input.length <= 6) {
      formattedNumber = `(${input.slice(0, 3)}) ${input.slice(3)}`;
    } else {
      formattedNumber = `(${input.slice(0, 3)}) ${input.slice(
        3,
        6
      )}-${input.slice(6, 10)}`;
    }

    setUserDetails((prev) => ({ ...prev, mobile: formattedNumber }));

    const isPhoneValid = validatePhone(formattedNumber);

    if (!isPhoneValid) {
      setErrors((prev) => ({ ...prev, mobile: true }));
    } else {
      setErrors((prev) => ({ ...prev, mobile: false }));
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "firstName" || name === "lastName") {
      setUserDetails((prev) => ({ ...prev, [name]: value }));
      if (value.trim() === "") {
        setErrors((prev) => ({ ...prev, [name]: true }));
      } else {
        setErrors((prev) => ({ ...prev, [name]: false }));
      }
    }
  };

  const getSelectedName = (data: any, selected: any, name: string) => {
    const selectedItem = data?.find((item: any) => item.id === selected);
    if (name === "county") {
      return selectedItem ? selectedItem.countyName : "";
    }
    if (name === "school") {
      return selectedItem ? selectedItem.schoolName : "";
    }
    return "";
  };

  const getSaveBtnDisabled = () => {
    const userRole = getItem("userRole");
    if (
        !userDetails.firstName ||
        !userDetails.lastName ||
        !userDetails.email ||
        (userRole !== "PARENT" && (!userDetails.county || !userDetails.school))
      ) {
        return true;
      }

    const isError: boolean = Object.keys(errors).some((key) => errors[key]);
    if (isError) {
      return true;
    }

    return false;
  };

  const handleSave = async () => {
    try {
        let payload = {
          firstName: userDetails.firstName.trim(),
          lastName: userDetails.lastName.trim(),
          mobile: `+1${formatPhoneNumberToInternational(userDetails.mobile)}`,
          role: userDetails.role,
          county: userDetails.county,
          school: userDetails.school,
        };
        setSaveLoading(true);
        let response = await updateExistingUser(payload).unwrap();
        if (response.status === "success") {
          handleTokenChanges(response?.data?.token);
          setSaveLoading(false);
          dispatch(closeDialog());
          setApiError(false);
        } else {
          setApiError(true);
          setSaveLoading(false);
        }
    } catch (error) {
        setApiError(true);
        setSaveLoading(false);
    }
  };

  const handleTokenChanges = (token:string) => {
    if (token) {
      setItem("token", token);
      const decoded: any = jwtDecode(token);
      setItem("userName", decoded?.firstName);
      setItem("userRole", decoded?.role ?? null);
      setItem("userEmail", decoded?.userEmail);
      const expiryTime = decoded.exp;
      if (expiryTime) {
        setItem("expiryTime", expiryTime.toString());
      }
    }
  }

  useEffect(() => {
    if (userDataSuccess) {
      const tempData: UserDetailsData = {
        firstName: userData.data?.firstName ?? "",
        lastName: userData.data?.lastName ?? "",
        email: userData.data?.email ?? "",
        mobile: convertToUSANumber(userData.data?.mobile) ?? "",
        role: userData.data?.role ?? "",
        county: userData.data?.county ?? "",
        school: userData.data?.school ?? "",
      };
      fetchSchool(userData.data?.county);
      setUserDetails(tempData);
      setApiError(false);
    }
    if(userDataError){
        setApiError(true);
    }
  }, [userDataLoading, userDataSuccess, userData]);

  return (
    <div className="px-3 py-6 gap-9 flex flex-col items-center">
      <h1 className="font-gully text-[40px] tracking-wide font-bold text-center text-appPrimaryColor">
        Edit profile
      </h1>

      <div className="w-full">
        {apiError && (
          <div className="flex w-full items-center py-2 px-2 border border-[#d00000] rounded-xl">
            <div>
              <GrCircleAlert size={24} className="text-[#D00000] mr-[10px]" />
            </div>
            <p className="text-[#D00000] text-sm font-medium">
              There was an issue, please wait a moment and try again.
            </p>
          </div>
        )}
        <CustomTextField
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(e)
          }
          placeholder="First Name"
          type={"text"}
          name="firstName"
          mandatory={true}
          value={userDetails?.firstName ?? ""}
          isError={errors.firstName}
          className={"w-full !border-[#646464] mt-6"}
        />
        <CustomTextField
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(e)
          }
          placeholder="Last Name"
          type={"text"}
          name="lastName"
          mandatory={true}
          value={userDetails?.lastName ?? ""}
          isError={errors.lastName}
          className={"w-full !border-[#646464] mt-6"}
        />
        <CustomTextField
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(e)
          }
          placeholder="Email"
          type={"text"}
          name="email"
          value={userDetails?.email ?? ""}
          className={"w-full !border-[#646464] mt-6"}
          disabled
        />
        {getItem("userRole") === "STUDENT" && userData?.data?.role && (
          <>
            <Dropdown
              getOption={(option: any) => `${option.countyName}`}
              onChangeHandler={handleDropdownChange}
              setOpen={setIsCountyDropdownOpen}
              open={isCountyDropdownOpen}
              selected={getSelectedName(
                countyData ?? [],
                userDetails?.county,
                "county"
              )}
              options={countyData ?? []}
              isError={errors.county}
              label="County"
              name="county"
            />
            <Dropdown
              getOption={(option: any) => `${option.schoolName}`}
              onChangeHandler={handleDropdownChange}
              setOpen={setIsSchoolDropdownOpen}
              open={isSchoolDropdownOpen}
              selected={getSelectedName(
                schoolData,
                userDetails?.school,
                "school"
              )}
              options={schoolData || []}
              isError={errors.school}
              label="School"
              name="school"
            />
          </>
        )}

        <CustomTextField
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
            handlePhoneChange(e)
          }
          placeholder="Phone Number"
          type={"text"}
          name="mobile"
          value={userDetails?.mobile ?? ""}
          className="w-full !border-[#646464]"
          isError={errors.mobile}
          containerClass="mt-6"
        />
      </div>

      <div className="flex justify-between items-center self-stretch">
        <AuthButton
          text="Back"
          icon={<MdArrowBackIosNew size={16}/>}
          iconPosition="left"
          onClick={handleCloseDialog}
          loader={false}
          disabled={false}
        />
        <AuthButton
          text="Save"
          icon={<BiSave size={24}/>}
          iconPosition="right"
          onClick={handleSave}
          loader={saveLoading}
          disabled={getSaveBtnDisabled() || saveLoading}
        />
      </div>
    </div>
  );
};

export default EditProfile;
