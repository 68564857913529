import { IoMenuOutline } from "react-icons/io5";

export const LineReaderDescription = () => (
    <>
      <p>
        <span className="font-bold">
          <IoMenuOutline className="inline-flex items-center flex-wrap" />
          Line Reader:</span> This tool helps you focus as you're reading test content. Click the
        six dots at the top of the line reader to move it up. and down using
        your keyboard arrows. If you're on a touch screen device, you can also
        move it with your finger. To see more than one line of text at a time,
        use the arrow button at the bottom of the line reader to change the
        number of visible lines.
      </p>
    </>
  );
    
export default LineReaderDescription;