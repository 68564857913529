import React, { useEffect, useRef } from "react";
import { IoCloseOutline } from "react-icons/io5";
import QuestionsOverView from "./QuestionsOverView";
import { StrikedOptionsData } from "../../redux/Slices/startFullTestSlice";

interface Answers {
  qId: string;
  userAnswer: string | undefined;
  userOption: string;
}

interface Answer {
  isActive: boolean;
  answerOption: string;
  isCorrectAnswer: boolean;
  correctAnswer: string;
  answerPlaceHolder: string;
}

interface ReadingModuleData {
  _id: string;
  isActive: boolean;
  description: string;
  question: string;
  explanation: string;
  moduleId: string;
  sectionId: string;
  answers: Answer[];
  questionTypeId: string;
  more: any; // You might want to replace 'any' with a more specific type
  additionalProps: any;
}

interface PopupProps {
  onClose: () => void;
  questionsList: ReadingModuleData[];
  userAnswers: Answers[];
  heading: () => React.ReactNode;
  headerHeading: string;
  count: number;
  isPopUp: boolean;
  strikedOutOptions: StrikedOptionsData[];
  setStartTime: any;
  module: 301 | 302;
  section: 701 | 702;
  startTime: any;
}

const PopUp = React.memo(
  ({
    onClose,
    questionsList,
    userAnswers,
    heading,
    count,
    isPopUp,
    strikedOutOptions,
    setStartTime,
    module = 301,
    section = 701,
    startTime,
    headerHeading,
  }: PopupProps) => {
    const popupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          popupRef.current &&
          !popupRef.current.contains(event.target as Node | null)
        ) {
          onClose();
        }
      };

      // Add click event listener
      document.addEventListener("mousedown", handleClickOutside);

      // Cleanup listener on unmount
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [onClose]);

    return (
      <>
        {isPopUp && (
          <div
            ref={popupRef}
            className="absolute bottom-[150%] left-[-175px] min-w-[530px] bg-white text-[#272727] border border-[#e4e4e4] z-50 rounded-lg"
          >
            <div className="mx-6 flex py-5 relative items-center">
              <div className="font-semibold mx-auto flex text-base">
                <span className="mr-1">{heading()}</span>
              </div>
              <button
                className="text-gray-600 hover:text-gray-800 absolute right-0"
                onClick={onClose}
              >
                <IoCloseOutline size={24} />
              </button>
            </div>
            <QuestionsOverView
              questionsList={questionsList}
              userAnswers={userAnswers}
              count={count}
              navigation={true}
              heading={headerHeading}
              strikedOutOptions={strikedOutOptions}
              setStartTime={setStartTime}
              module={module}
              section={section}
              startTime={startTime}
            />
          </div>
        )}
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.onClose === nextProps.onClose &&
      prevProps.questionsList === nextProps.questionsList &&
      prevProps.userAnswers === nextProps.userAnswers &&
      prevProps.heading() === nextProps.heading() &&
      prevProps.count === nextProps.count &&
      prevProps.isPopUp === nextProps.isPopUp
    );
  }
);

export default PopUp;
