import React from "react";
import { ImSpinner6 } from "react-icons/im";

interface ButtonProps {
  text: string;
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  disabled?: boolean;
  onClick?: () => void;
  loader?:boolean;
  className?:string;
}

const AuthButton: React.FC<ButtonProps> = ({
  text,
  icon,
  iconPosition = "left",
  disabled = false,
  onClick,
  loader,
  className,
}) => {
  return (
    <div>
      <button
        onClick={onClick}
        disabled={disabled}
        className={`${
          className || "text-[21px]"
        } flex items-center justify-center py-[14px] px-[34px] rounded-xl font-bold transition duration-200 ${
          disabled
            ? "bg-[#E1E1E1] text-[#646464] cursor-not-allowed"
            : "bg-[#327AEC] text-white hover:bg-white hover:text-[#327AEC] border border-[#327AEC]"
        }`}
      >
        {icon && iconPosition === "left" && (
          <span className="mr-2 flex items-center">{icon}</span>
        )}
        <span className="my-auto">{text}</span>
        {icon && iconPosition === "right" && (
          <span className="ml-2 flex items-center">{icon}</span>
        )}
        {loader && <ImSpinner6 className="animate-spin text-base text-white ml-2" />}
      </button>
    </div>
  );
};

export default AuthButton;
