import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../components/ReUsableComponents/CustomButton";
import {
  useGetUserDataByIdQuery,
  useProfileAssociationMutation,
  useProfileVerificationMutation,
} from "../../redux/API/ProfileSetup/profileSetupApi";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store/store";
import { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import AuthButton from "./LoginButton";

const ProfileAssociation = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id1 = searchParams.get("id1") ?? "";
  const id2 = searchParams.get("id2") ?? "";
  const decodedId1 = atob(id1);
  const decodedId2 = atob(id2);
  const [loading, setLoading] = useState(false);

  const [profileAssociation] = useProfileAssociationMutation();
  const [profileVerification] = useProfileVerificationMutation();
  const path = window.location.pathname;
  const navigate = useNavigate();
  const isParent = !!path.includes("parentassociate");
  const isStudent = path.includes("studentassociate");

  useGetUserDataByIdQuery(decodedId1, {
    refetchOnMountOrArgChange: true,
    skip: !isParent && !isStudent,
  });
  useGetUserDataByIdQuery(decodedId2, {
    refetchOnMountOrArgChange: true,
    skip: !isParent && !isStudent,
  });
  const { parentName, studentName } = useSelector(
    (state: RootState) => state.root.profileSetupSlice
  );
  const onClickHandler = async () => {
    setLoading(true);
    try {
      const payload = {
        studentId: decodedId2,
        parentId: decodedId1,
      };
      const params = {
        userId: isParent ? decodedId1 : decodedId2,
        verificationType: "ASSOCIATION",
      };
      const res = await profileVerification(params).unwrap();
      if (res?.status === "success") {
        const res2 = await profileAssociation(payload).unwrap();
        if (res2?.status === "success") {
          navigate("/login");
          setLoading(false);
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <div className="mt-36 flex justify-center flex-col items-center">
      <p className="mb-9 leading-[21.6px] text-center">
        <p className="font-bold text-[#327AEC] text-center text-[55px] font-pretendard cursor-pointer">
          S A T
        </p>
        <p className="font-bold text-[#327AEC] text-center text-[40px] font-pretendard cursor-pointer my-12">
          Account link request
        </p>
        {isParent ? (
          <div className="w-[440px]">
            Hi {parentName}! {studentName} has requested to be added to your
            account as a student. Do you accept?
          </div>
        ) : (
          <div className="w-[440px]">
            Hi {studentName}! {parentName}, has requested to be added to your
            account as a parent.If you accept, your existing associated parent
            will be replaced with F P.Do you accept?
          </div>
        )}
      </p>
      <div>
        <AuthButton
          text="Accept Request"
          icon={<FaPlus />}
          iconPosition="left"
          onClick={onClickHandler}
          loader={false}
          disabled={loading}
        />
      </div>
    </div>
  );
};
export default ProfileAssociation;
