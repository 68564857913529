import React, { useEffect, useState } from "react";
import CustomTextField from "../../components/ReUsableComponents/CustomTextField";
import CustomButton from "../../components/ReUsableComponents/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { setCreateAccount, setEmailError } from "../../redux/Slices/loginSlice";
import { useSignInMutation } from "../../redux/API/Login/loginApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setItem } from "../../utils/token";
import { jwtDecode } from "jwt-decode";
import { RootState } from "../../redux/Store/store";
import showIcon from "../../assets/Images/eyeopen.svg";
import hideIcon from "../../assets/Images/eyeclose.svg";

function SignIn(props: any) {
  const emailPattern = /^[a-zA-Z0-9][^\s@]*@[^\s@]+\.[a-zA-Z]+$/;
  const [userSignInData, setUserSignInData] = useState({
    userName: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [signIn, { isLoading: signInLoading }] = useSignInMutation();
  const { emailError } = useSelector(
    (state: RootState) => state.root.loginSlice
  );
  const dispatch = useDispatch();

  const handleChangeSignIn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserSignInData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateEmail = (email: string) => {
    if (!emailPattern.test(email)) {
      dispatch(setEmailError("Please Enter Valid Email Ex:abc@domain.com"));
      return false;
    }
    return true;
  };

  const handleSuccess = (response: any) => {
    toast.success("Login Successful");
    navigate("/dashboard");
    const { token, isLogin } = response;
    setItem("isLogin", isLogin);
    if (token) {
      setItem("token", token);
      const decoded: any = jwtDecode(token);
      setItem("userName", decoded?.name);
      setItem("userRole", decoded?.role ?? null);
      const expiryTime = decoded.exp;
      if (expiryTime) {
        setItem("expiryTime", expiryTime.toString());
      }
    }
  };

  const handleError = (error: any) => {
    if (error.status === 401) {
      toast.error("Invalid Credentials");
    } else {
      toast.error(error?.data?.message);
    }
  };

  const handleLogIn = async () => {
    if (!validateEmail(userSignInData.userName)) {
      return;
    }

    try {
      const logInResponse: any = await signIn(userSignInData).unwrap();
      if (logInResponse.isLogin) {
        handleSuccess(logInResponse);
      } else {
        toast.error(logInResponse?.message);
      }
    } catch (error: any) {
      handleError(error);
    }
  };
  useEffect(() => {
    setItem("token", "");
  }, []);

  return (
    <div className="flex flex-col">
      <div className="font-bold text-primaryColor text-center text-4xl font-pretendard cursor-pointer mb-5">
        S A T
      </div>
      <div className="flex justify-center gap-4">
        <h3 className="text-primaryColor font-extrabold text-xl">
          <span>Sign in</span>
          <span className="text-[#333]">or</span>
          <span>Join Us</span>
          <span className="text-[#333]">Today!</span>
        </h3>
      </div>
      <CustomTextField
        label="Email Id"
        onChangeHandler={handleChangeSignIn}
        placeholder="Enter Your Email "
        type="text"
        name="userName"
        mandatory={true}
        value={userSignInData.userName}
      />
      <div className="text-lg text-red-500 mt-2">{emailError}</div>
      <div className="flex justify-between relative">
        <CustomTextField
          label="Password"
          onChangeHandler={handleChangeSignIn}
          placeholder="Enter Your Password"
          type={`${showPassword ? "text" : "password"}`}
          name="password"
          mandatory={true}
          value={userSignInData.password}
        />
        {userSignInData.password && (
          <button
            className="absolute right-8 bottom-2 cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <img src={showIcon} alt="viewPass" />
            ) : (
              <img src={hideIcon} alt="hidePass" />
            )}
          </button>
        )}
      </div>

      <div className="mt-8 flex">
        <CustomButton
          width="w-full"
          name="Log In"
          loader={signInLoading}
          disabled={
            signInLoading ||
            userSignInData.userName === "" ||
            userSignInData.password === ""
          }
          onClickHandler={handleLogIn}
        />
      </div>
      <button
        className="mt-6 underline font-bold text-[#424242] flex justify-center text-xl cursor-pointer"
        onClick={() => {
          props.setIsAnimated(!props.isAnimated);
          dispatch(setCreateAccount(true));
        }}
      >
        Create Account
      </button>
    </div>
  );
}

export default SignIn;
