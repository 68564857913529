import { BiChevronDown, BiFilterAlt } from "react-icons/bi";
import { RootState } from "../../redux/Store/store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  setCurrentQuestion,
  setCurrentQuestionIndex,
} from "../../redux/Slices/resultSlice";
import { PiMinusCircleBold } from "react-icons/pi";
import { LuArrowLeftFromLine } from "react-icons/lu";
import {
  setFilteredData,
  setFilters,
  setMinimisePanel,
  setQid,
} from "../../redux/Slices/startFullTestSlice";
import { MdOutlineCancel } from "react-icons/md";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import FiltersPopUp from "./FiltersPopUp";

export type Category = "section" | "module" | "answerType";
export type FilterValue =
  | "readingWriting"
  | "math"
  | "module1"
  | "module2"
  | "correct"
  | "incorrect"
  | "omitted";

const QuestionsLeftPane = () => {
  const { completedTestData, filters, minimisePanel, filteredData, qId } =
    useSelector((state: RootState) => state.root.startFullTest);
  const dispatch = useDispatch();
  const { section, module, answerType } = filters;
  const { math, readingWriting } = section;
  const { module1, module2 } = module;
  const { correct, incorrect, omitted } = answerType;

  const { currentQuestion } = useSelector(
    (state: RootState) => state.root.resultsSlice
  );

  const [showFilters, setShowFilters] = useState(false);
  const [expandedSections, setExpandedSections] = useState<any>({
    readingWriting: true,
    math: true,
  });

  const toggleSection = (section: any) => {
    setExpandedSections((prev: any) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const toggleFilter = (category: Category, value: FilterValue) => {
    const newValue =
      !filters[category][value as keyof (typeof filters)[Category]];
    const categoryValues = Object.values(filters[category]);
    if (!newValue && categoryValues.filter(Boolean).length === 1) return;
    dispatch(
      setFilters({
        ...filters,
        [category]: {
          ...filters[category],
          [value]:
            !filters[category][value as keyof (typeof filters)[Category]],
        },
      })
    );
  };

  const filterData = () => {
    return completedTestData?.additionalProps?.overallresult?.allQuestionsTableData.filter(
      (item: any) => {
        const sectionMatch =
          (item.section.startsWith("Reading and Writing") && readingWriting) ||
          (item.section.startsWith("Math") && math);

        const moduleMatch =
          (item.section.includes("Module1") && module1) ||
          (item.section.includes("Module2") && module2);

        const answerTypeMatch =
          (item.isCorrect && item.yourChoice !== "Omitted" && correct) ||
          (!item.isCorrect && item.yourChoice !== "Omitted" && incorrect) ||
          (item.yourChoice === "Omitted" && omitted);

        return sectionMatch && moduleMatch && answerTypeMatch;
      }
    );
  };
  useEffect(() => {
    const data = filterData();
    if (data?.length > 0) {
      dispatch(setFilteredData(data));
      const isQuesExists = data?.some((item: any) => item._id === qId);
      if (data?.length > 0 && !isQuesExists) {
        const type =
          data[0]?.section?.split?.(":")[0]?.trim() === "Math" ? "math" : "rw";
        getModuleAndSection(data[0], type);
      }
    } else {
      dispatch(setFilteredData([]));
    }
  }, [filters, completedTestData, qId]);

  const readingWritingQuestions = filteredData?.filter((q: any) =>
    q.section.startsWith("Reading and Writing")
  );
  const mathQuestions = filteredData?.filter((q: any) =>
    q.section.startsWith("Math")
  );

  const getModuleAndSection = (section: any, type: string) => {
    const module = section?.section?.split(":")[1]?.trim();
    dispatch(setQid(section?._id));

    dispatch(setCurrentQuestionIndex(section?.no - 1));

    if (type === "rw" && module === "Module1") {
      dispatch(setCurrentQuestion("readingModuleOneOverallResult"));
    }
    if (type === "rw" && module === "Module2") {
      dispatch(setCurrentQuestion("readingModuleTwoOverallResult"));
    }
    if (type === "math" && module === "Module1") {
      dispatch(setCurrentQuestion("mathsModuleOneOverallResult"));
    }
    if (type === "math" && module === "Module2") {
      dispatch(setCurrentQuestion("mathsModuleTwoOverallResult"));
    }
  };

  const getModuleAndQuestion = (q: any) => {
    const module = q?.section?.split(":")?.[1]?.trim();
    if (minimisePanel && module === "Module1") {
      return `M1 - Q${q?.no}`;
    } else if (minimisePanel && module === "Module2") {
      return `M2 - Q${q?.no}`;
    } else {
      return `${q?.section?.split(":")?.[1]} - Question ${q?.no}`;
    }
  };

  const renderChoiceIcon = (q: any) => {
    if (q.isCorrect && q.yourChoice !== "Omitted") {
      return (
        <IoIosCheckmarkCircleOutline size={24} className="text-[#0B6E4F]" />
      );
    } else if (q.yourChoice === "Omitted") {
      return (
        <p className="text-[#202020]">
          <PiMinusCircleBold size={24} />
        </p>
      );
    } else {
      return <MdOutlineCancel size={24} className="text-[#D00000]" />;
    }
  };

  const countTrueValues = (obj: any) => {
    let count = 0;
    Object.values(obj).forEach((innerObj: any) => {
      Object.values(innerObj).forEach((value) => {
        if (value === true) {
          count++;
        }
      });
    });
    return count;
  };

  const trueCount = countTrueValues(filters);

  return (
    <div
      style={{ boxShadow: "0px 0px 10px 5px #20202026" }}
      className={`border rounded-xl transition-all duration-300 ease-in-out ${
        minimisePanel ? "w-40" : "min-w-[300px]"
      }`}
    >
      <div className="p-4 border-b border-appPrimaryColor">
        <div className="flex items-center justify-between">
          <span className="font-medium text-[21px] text-appPrimaryColor">
            {minimisePanel ? "Qu..." : "Questions"}
          </span>
          <div className="flex space-x-2">
            <div className="relative">
              <button
                onClick={() => setShowFilters(!showFilters)}
                className="p-1 hover:bg-gray-100 rounded"
              >
                <BiFilterAlt
                  size={24}
                  className="font-medium text-appPrimaryColor"
                />
                {trueCount < 7 && (
                <span className="text-sm absolute -top-[3px] right-0 bg-black text-white rounded-full w-[10px] h-[10px] p-2 flex items-center justify-center">
                    {trueCount ?? ""}
                    </span>
                  )}
                </button>

              {/* Filters Dropdown */}
              {showFilters && (
                <FiltersPopUp
                  toggleFilter={toggleFilter}
                  showFilters={showFilters}
                  setShowFilters={setShowFilters}
                />
              )}
            </div>
            <button
              onClick={() => dispatch(setMinimisePanel(!minimisePanel))}
              className="p-1 hover:bg-gray-100 rounded transform transition-transform"
            >
              <LuArrowLeftFromLine
                size={24}
                className={`font-medium text-appPrimaryColor ${
                  minimisePanel ? "rotate-180" : ""
                }`}
              />
            </button>
          </div>
        </div>
      </div>

      {readingWriting && (
        <button
          onClick={() => toggleSection("readingWriting")}
          className="w-full py-4 px-3 text-left flex justify-between items-center border-b border-[#646464]"
        >
          <span className="font-medium text-[21px]">
            {minimisePanel ? "Reading..." : "Reading And Writing"}
          </span>
          <BiChevronDown
            size={24}
            className={`transform ${
              expandedSections.readingWriting ? "rotate-180" : ""
            }`}
          />
        </button>
      )}
      {expandedSections.readingWriting && readingWriting && (
        <div className="pb-4">
          {readingWritingQuestions?.map((q: any, i: number) => (
            <button
              key={q._id}
              className={`px-3 border-b border-[#E1E1E1] w-full flex items-center justify-between space-x-2 p-2 rounded hover:bg-gray-50 cursor-pointer ${
                currentQuestion === q._id ? "bg-blue-50" : ""
              }`}
              onClick={() => getModuleAndSection(q, "rw")}
            >
              <span className="text-lg">{getModuleAndQuestion(q)}</span>
              <span>{renderChoiceIcon(q)}</span>
            </button>
          ))}
        </div>
      )}

      {math && (
        <button
          onClick={() => toggleSection("math")}
          className="w-full mb-2 py-4 px-3 text-left flex justify-between items-center border-b border-[#646464]"
        >
          <span className="font-medium text-[21px]">Math</span>
          <BiChevronDown
            size={24}
            className={`transform ${expandedSections.math ? "rotate-180" : ""}`}
          />
        </button>
      )}
      {expandedSections.math && (
        <div className="pb-4">
          {mathQuestions?.map((q: any, i: number) => (
            <button
              key={q._id}
              className={`px-3 border-b border-[#E1E1E1] w-full flex items-center justify-between space-x-2 p-2 rounded hover:bg-gray-50 cursor-pointer ${
                currentQuestion === q._id ? "bg-blue-50" : ""
              }`}
              onClick={() => getModuleAndSection(q, "math")}
            >
              <span className="text-lg">{getModuleAndQuestion(q)}</span>
              <span>{renderChoiceIcon(q)}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default QuestionsLeftPane;
