import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {RootState} from '../../redux/Store/store';
import {closeDialog} from '../../redux/Slices/dialogSlice'


const CustomDialog = ({dialogTitle,maxWidth='max-w-lg',children}:{dialogTitle:string,maxWidth:string,children:any}) => {
    
    const isOpen = useSelector((state:RootState) => state.root.dialog.isOpen);
    const dispatch = useDispatch()

    function closeModal() {
     dispatch(closeDialog())

    }
  
  
    return (
      <div>
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>
  
            <div className="fixed inset-0 ">
              <div className="flex min-h-full items-center justify-center text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className={`md:w-full w-[80%] ${maxWidth} transform  rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all`}>
                    {dialogTitle &&<Dialog.Title
                      as="h3"
                      className="md:text-xl text-sm md:font-medium bg-[#F8F9FA] py-4 md:px-6 px-2 leading-6 text-gray-900 flex justify-between border-b border-b-[#E3E5E9]"
                    >
                     <button onClick={closeModal}>{dialogTitle}</button>
                    </Dialog.Title>}
                   {children}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    )
}

export default CustomDialog