import React, { useState } from "react";
import { RiArrowLeftSLine, RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineCheck, MdOutlineRemoveRedEye } from "react-icons/md";
import AuthButton from "./LoginButton";
import { RiArrowRightSLine } from "react-icons/ri";
import { setCurrentStep } from "../../redux/Slices/profileSetupSlice";
import CustomTextField from "../../components/ReUsableComponents/CustomTextField";
import Dropdown from "./Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/Store/store";
import { getSelectedName } from "./StudentDetails";

export type StudentDetailsFormType = {
  isStudentRoute: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setConfirmPassword: (val: string) => void;
  confirmPassword: string;
  setError: (val: string) => void;
  loading: boolean;
  checkIsEmailExists: any;
  handleDropdownChange: any;
  handleActionButton: any;
  open: boolean;
  isCountyDropdownOpen: boolean;
  setIsCountyDropdownOpen: (val: boolean) => void;
  setOpen: (val: boolean) => void;
};

const StudentDetailsForm = ({
  isStudentRoute,
  handleChange,
  setConfirmPassword,
  confirmPassword,
  setError,
  loading,
  handlePhoneChange,
  handleActionButton,
  handleDropdownChange,
  checkIsEmailExists,
  isCountyDropdownOpen,
  setIsCountyDropdownOpen,
  open,
  setOpen,
}: StudentDetailsFormType) => {
  const dispatch = useDispatch();
  const { currentStep, userData, countyData, schoolData, userDataErrors } =
    useSelector((state: RootState) => state.root.profileSetupSlice);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const {
    studentFirstName,
    studentLastName,
    studentUserName,
    studentPhoneNumber,
    studentPassword,
    county,
    school,
  } = userData;

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  const selectedCounty = getSelectedName(countyData, county, "county");

  return (
    <div className="w-[446px]">
      <CustomTextField
        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(e)
        }
        placeholder="First Name"
        type={"text"}
        name="studentFirstName"
        mandatory={true}
        value={studentFirstName}
        isError={userDataErrors.studentFirstName}
        className={"w-full !border-[#646464]"}
      />
      <CustomTextField
        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(e)
        }
        placeholder="Last Name"
        type={"text"}
        name="studentLastName"
        mandatory={true}
        value={studentLastName}
        className="w-full !border-[#646464]"
        isError={userDataErrors.studentLastName}
        containerClass="mt-6"
      />
      {(currentStep === "parentStudentDetails" || isStudentRoute) && (
        <CustomTextField
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(e)
          }
          placeholder="Email"
          type={"text"}
          name="studentUserName"
          mandatory={true}
          value={studentUserName?.trim()}
          className="w-full !border-[#646464]"
          isError={userDataErrors.studentUserName}
          containerClass="mt-6"
          disabled={isStudentRoute}
          onBlur={(e) => checkIsEmailExists(e.target.value)}
        />
      )}

      <Dropdown
        getOption={(option: any) => `${option.countyName}`}
        onChangeHandler={handleDropdownChange}
        setOpen={setIsCountyDropdownOpen}
        open={isCountyDropdownOpen}
        selected={getSelectedName(countyData, county, "county")}
        options={countyData || []}
        isError={userDataErrors.county}
        label="County"
        name="county"
      />
      <Dropdown
        getOption={(option: any) => `${option.schoolName}`}
        onChangeHandler={handleDropdownChange}
        setOpen={setOpen}
        open={open}
        selected={getSelectedName(schoolData, school, "school")}
        options={schoolData || []}
        isError={userDataErrors.school}
        label="School"
        name="school"
      />
      <CustomTextField
        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
          handlePhoneChange(e)
        }
        placeholder="Phone Number"
        type={"text"}
        name="studentPhoneNumber"
        value={studentPhoneNumber}
        className="w-full !border-[#646464]"
        isError={userDataErrors.studentPhoneNumber}
        containerClass="mt-6"
      />
      {isStudentRoute && (
        <>
          <div className="flex justify-between relative mt-6">
            <CustomTextField
              onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e)
              }
              placeholder="Password"
              type={`${showPassword ? "text" : "password"}`}
              name="studentPassword"
              value={studentPassword?.trim()}
              className="w-full !border-[#646464]"
              isError={userDataErrors.studentPassword}
            />
            {studentPassword && (
              <button
                className={`${
                  userDataErrors.studentPassword
                    ? "text-[#D00000]"
                    : "text-[#646464]"
                } absolute right-6 bottom-2 cursor-pointer text-2xl`}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <MdOutlineRemoveRedEye /> : <RiEyeCloseLine />}
              </button>
            )}
          </div>
          <div className="flex justify-between relative mt-6">
            <CustomTextField
              onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                setConfirmPassword(e.target.value)
              }
              placeholder="Confirm Password"
              type={`${showConfirmPassword ? "text" : "password"}`}
              name="studentPassword"
              value={confirmPassword?.trim()}
              className="w-full !border-[#646464]"
              isError={!setError}
              onPaste={handlePaste}
            />
            {confirmPassword && (
              <button
                className="absolute right-6 bottom-2 cursor-pointer text-2xl text-[#646464]"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <MdOutlineRemoveRedEye />
                ) : (
                  <RiEyeCloseLine />
                )}
              </button>
            )}
          </div>
        </>
      )}

      <div
        className={`mt-9 flex items-center ${
          isStudentRoute ? "justify-center" : "justify-between"
        }`}
      >
        {!isStudentRoute && (
          <AuthButton
            text="Back"
            icon={<RiArrowLeftSLine />}
            iconPosition="left"
            onClick={() => dispatch(setCurrentStep("parentPersonalDetails"))}
            loader={false}
            disabled={currentStep === "studentPersonalDetails"}
          />
        )}
        <AuthButton
          text={handleActionButton()?.buttonText}
          icon={
            currentStep === "studentPersonalDetails" ? (
              <RiArrowRightSLine size={24} />
            ) : (
              <MdOutlineCheck size={24} />
            )
          }
          iconPosition="right"
          onClick={handleActionButton()?.action}
          loader={false}
          disabled={loading}
        />
      </div>
    </div>
  );
};

export default StudentDetailsForm;
