export const LinkToreferencedContentDescription = () => (
    <>
      <p>
        On questions that refer to a specific section of the stimulus content,
        this symbol<span className="font-bold"> {" >>"} </span> will display after the
        reference. If you're using a screen reader and you navigate to "link
        to referenced content,"the referenced content will be read to you. For
        all users, if you scroll past the referenced content and you click on
        this symbol, the passage will scroll back so the referenced content is
        at the top of your screen.
      </p>
    </>
  );
    
    export default LinkToreferencedContentDescription;