import { useEffect, useState } from "react";
import QuestionsLeftPane from "./QuestionsLeftPane";
import RawScoreCard from "./RawScoreCard";
import FinalScoreCard from "./FinalScoreCard";
import QuestionContent from "./QuestionContent";
import Explanation from "./Explanation";
import { useGetUserResultsByIdQuery } from "../../redux/API/StartTest/startTestApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/Store/store";
import { useNavigate, useParams } from "react-router-dom";
import { getItem } from "../../utils/token";
import { setIsTestStarted, setResetFilters, setQid, setIsTestInstructionsOpen } from "../../redux/Slices/startFullTestSlice";
import LinkExpired from "../ReUsableComponents/LinkExpired";
import AuthButton from "../../pages/LogInPage/LoginButton";
import { setCurrentQuestionIndex } from "../../redux/Slices/resultSlice";
import { setCurrentQuestion } from "../../redux/Slices/resultSlice";

// Types

export type ScoreModule = {
  name: string;
  score: string;
  total?: string;
  subModules?: {
    name: string;
    score: string;
  }[];
};
export type ScoreSection = {
  title: string;
  totalScore: string;
  modules: ScoreModule[];
};

const TestResults = () => {
  const [openPanels, setOpenPanels] = useState({
    rawScore: true,
    finalScore: true,
  });
  const { isFullTestResultScreen, filteredData } = useSelector(
    (state: RootState) => state.root.startFullTest
  );
   const {isError } = useSelector(
     (state: RootState) => state.root.loaderSlice
   );
  const { id } = useParams();
  const isParent = getItem("userRole") === "PARENT";
  const userId = isParent ? getItem("studentId") ?? "" : "";
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsTestStarted(false));
    dispatch(setIsTestInstructionsOpen(false));
  }, [dispatch]);

  useGetUserResultsByIdQuery(
    { userId, testId: id || "" },
    { refetchOnMountOrArgChange: true, skip: !id }
  );

  const navigate = useNavigate();

  const togglePanel = (panel: keyof typeof openPanels) => {
    setOpenPanels({ ...openPanels, [panel]: !openPanels[panel] });
  };

  const handleClickExit = () => {
   dispatch(setQid(""));
   dispatch(setCurrentQuestion(""));
   dispatch(setCurrentQuestionIndex(""));
   dispatch(setResetFilters());

    if (isParent) {
      navigate("/parentdashboard");
    } else {
      navigate("/practices/results");
    }
  };

  return (
    <div className="w-full mt-8 mb-4 mx-auto bg-white rounded-lg px-4 font-gully">
      {isError ? (
        <LinkExpired redirectTo="/dashboard" />
      ) : (
        <div className="flex gap-2">
          {!isFullTestResultScreen && <QuestionsLeftPane />}
          <div className={"flex-grow"}>
            {!isFullTestResultScreen && (
              <div className="flex gap-2 mb-2">
                {/* Raw Score Card */}
                <RawScoreCard
                  togglePanel={(panel) =>
                    togglePanel(panel as "rawScore" | "finalScore")
                  }
                  openPanels={openPanels}
                />

                {/* Final Score Card */}
                <FinalScoreCard
                  togglePanel={(panel) =>
                    togglePanel(panel as "rawScore" | "finalScore")
                  }
                  openPanels={openPanels}
                />

                <div className="basis-[10%]">
                  <AuthButton
                    text="Exit"
                    onClick={handleClickExit}
                    className="h-[60px] font-bold text-white px-6 text-[21px]"
                  />
                </div>
              </div>
            )}
            <div
              style={{ boxShadow: "0px 0px 10px 5px #20202026" }}
              className="rounded-xl px-6 pt-6 pb-2 mb-2 w-full"
            >
              <QuestionContent />
            </div>
            {!isFullTestResultScreen && filteredData?.length > 0 && (
              <div
                style={{ boxShadow: "0px 0px 10px 5px #20202026" }}
                className="p-6 rounded-xl"
              >
                <Explanation />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TestResults;
