import api from "../createApi";

export const profileSetupApi = api.injectEndpoints({
  endpoints: (builder) => ({
    studentProfile: builder.mutation({
      query: (payload) => ({
        url: "/profile/student",
        method: "PUT",
        body: payload,
      }),
    }),
    createParent: builder.mutation({
      query: (payload) => ({
        url: "/profile/createparent",
        method: "POST",
        body: payload,
      }),
    }),
    parentProfile: builder.mutation({
      query: (payload) => ({
        url: "/profile/parent",
        method: "PUT",
        body: payload,
      }),
    }),
    createStudent: builder.mutation({
      query: (payload) => ({
        url: "/profile/createstudent",
        method: "POST",
        body: payload,
      }),
    }),
    countyGetAll: builder.query({
      query: () => ({
        url: "/county/getall",
        method: "GET",
      }),
    }),
    getCountySchoolById: builder.query({
      query: (countyId) => ({
        url: "/countyschools/getbyid",
        method: "GET",
        params: { countyId },
      }),
    }),
    getUserDataById: builder.query({
      query: (userId) => ({
        url: "/user/getbyid",
        method: "GET",
        params: { userId },
      }),
    }),
    updateUser: builder.mutation({
      query: ({ userId, payload }) => ({
        url: "/user/updateuser",
        method: "PUT",
        body: payload,
        params: { userId },
      }),
    }),
    profileAssociation: builder.mutation({
      query: (payload) => {
        const { parentId, studentId } = payload;
        return {
          url: "/profile/association",
          method: "POST",
          params: { parentId, studentId },
        };
      },
    }),
    profileVerification: builder.mutation({
      query: (params) => {
        const { userId, verificationType } = params;
        return {
          url: "/profile/verification",
          method: "PUT",
          params: { userId, verificationType },
        };
      },
    }),
    isUserExists: builder.query({
      query: (payload) => {
        const { email, role } = payload;
        return {
          url: `/user/isuserexists`,
          method: "GET",
          params: { email, role },
        };
      },
    }),
    isEmailExists: builder.query({
      query: (payload) => {
        const { email } = payload;
        return {
          url: `/user/isemailexists`,
          method: "GET",
          params: { email },
        };
      },
    }),
    associatedetails: builder.query({
      query: () => {
        return {
          url: `/user/fetch/student/associatedetails`,
          method: "GET",
        };
      },
    }),
    profileStatus: builder.query({
      query: () => {
        return {
          url: `/profile/status`,
          method: "GET",
        };
      },
    }),
  }),
});
export const {
  useStudentProfileMutation,
  useParentProfileMutation,
  useCountyGetAllQuery,
  useLazyGetCountySchoolByIdQuery,
  useGetCountySchoolByIdQuery,
  useGetUserDataByIdQuery,
  useCreateParentMutation,
  useCreateStudentMutation,
  useUpdateUserMutation,
  useProfileAssociationMutation,
  useProfileVerificationMutation,
  useLazyIsUserExistsQuery,
  useAssociatedetailsQuery,
  useLazyAssociatedetailsQuery,
  useLazyIsEmailExistsQuery,
  useLazyProfileStatusQuery,
} = profileSetupApi;

