import { HiArrowsPointingIn, HiArrowsPointingOut } from "react-icons/hi2";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { RootState } from "../../redux/Store/store";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentQuestion,
  setCurrentQuestionIndex,
} from "../../redux/Slices/resultSlice";
import { useEffect, useRef, useState } from "react";
import {
  setIsFullTestResultScreen,
  setQid,
} from "../../redux/Slices/startFullTestSlice";
import RenderText from "../ReUsableComponents/RenderText";
import MathsModuleDirections from "../ReUsableComponents/MathsModuleDirections";
import { GetFile } from "../../utils";

const QuestionContent = () => {
  const { currentQuestion, currentQuestionIndex } = useSelector(
    (state: RootState) => state.root.resultsSlice
  );

  const { completedTestData, isFullTestResultScreen, qId, filteredData,count } =
    useSelector((state: RootState) => state.root.startFullTest);

  const dispatch = useDispatch();

  const questionData = completedTestData?.additionalProps?.overallresult?.[
    currentQuestion
  ]?.find((q: any) => q._id === qId);

  const [url, setUrl] = useState("");

  const getQuestionTime = (questionId: string) => {
    const milliseconds = completedTestData?.activity?.[questionId];
    if (!milliseconds) return null;
    const minutes = Math.floor(milliseconds / 1000 / 60);
    const seconds = Math.floor((milliseconds / 1000) % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")} mins`;
  };

  const time = getQuestionTime(questionData?._id);

  const {
    question,
    answers,
    userOption,
    exactAnswer,
    userAnswer,
    questionContentName,
    processedQuestion,
    description,
    isCorrect,
    imageDetails,
    questionContentDomain,
  } = questionData || {};
  
    const abortControllerRef = useRef<AbortController | null>(null);
    const currentQuestionRef = useRef<number | null>(null);

    useEffect(() => {
      setUrl("");

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        abortControllerRef.current = null;
      }

      abortControllerRef.current = new AbortController();
      const { signal } = abortControllerRef.current;

      currentQuestionRef.current = count;

      const imageDetailKeys = Object.keys(
        imageDetails || {}
      );

      const image =
        imageDetailKeys.length > 0
          ? imageDetails[imageDetailKeys[0]]
          : null;

      const fetchImage = async () => {
        try {
          const questionCountAtFetch = count;
          const res = await GetFile(image?.fileName, { signal });
          if (
            !signal.aborted &&
            questionCountAtFetch === currentQuestionRef.current &&
            res
          ) {
            setUrl(res);
          }
        } catch (error) {
          if (
            error instanceof Error &&
            error.name !== "AbortError" &&
            count === currentQuestionRef.current
          ) {
            console.error("Error fetching image:", error);
            setUrl("");
          }
        }
      };

      if (image && image?.fileName) {
        fetchImage();
      }

      return () => {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
          abortControllerRef.current = null;
        }
        currentQuestionRef.current = null;
      };
    }, [imageDetails, currentQuestion,qId]);

  const getAnswerBorderColor = (option: string) => {
    if (option === exactAnswer && option === userOption) {
      return "border-[#0B6E4F]";
    } else if (option === exactAnswer) {
      return "border-[#0B6E4F]";
    } else if (option === userOption) {
      return "border-[#D00000]";
    }
    return "border-[#202020]";
  };

  const getAnswerTextColor = (option: string) => {
    if (option === exactAnswer && option === userOption) {
      return "bg-[#0B6E4F] text-white";
    } else if (option === exactAnswer) {
      return "bg-[#0B6E4F] text-white";
    } else if (option === userOption) {
      return "bg-[#D00000] text-white";
    }
    return "bg-[#E1E1E1] text-[#202020]";
  };

  const getAnswerText = () => {
    if (userOption) {
      return userOption;
    } else if (userAnswer) {
      return userAnswer;
    } else {
      return "";
    }
  };

  let questionResult =
    completedTestData?.additionalProps?.overallresult?.[currentQuestion]?.[
      currentQuestionIndex
    ] || {};

  const currentIndex = filteredData?.findIndex(
    (q: any) => q._id === questionResult?._id
  );
  const disableNext = currentIndex === filteredData?.length - 1;

  const handleFilteredNavigate = (
    direction: "next" | "back",
    filteredQuestions: Array<{
      section: string;
      no: number;
      questionType: string;
      correctAnswer: string;
      yourChoice: string;
      isCorrect: boolean;
      _id: string;
    }>,
    currentQuestionId: string
  ) => {
    // Find current question index in filtered list
    const currentIndex = filteredQuestions.findIndex(
      (q) => q._id === currentQuestionId
    );

    if (currentIndex === -1) return null;

    let nextIndex: number;

    if (direction === "next") {
      // If we're at the last question, loop to first question
      nextIndex =
        currentIndex === filteredQuestions.length - 1 ? 0 : currentIndex + 1;
    } else {
      // If we're at the first question, loop to last question
      nextIndex =
        currentIndex === 0 ? filteredQuestions.length - 1 : currentIndex - 1;
    }

    const nextQuestion = filteredQuestions[nextIndex];

    // Get module and type info from section
    const isReadingWriting = nextQuestion.section.includes(
      "Reading and Writing"
    );
    const moduleNumber = nextQuestion.section.includes("Module1")
      ? "Module1"
      : "Module2";

    return {
      questionId: nextQuestion._id,
      moduleNumber,
      type: isReadingWriting ? "rw" : "math",
      questionNumber: nextQuestion.no,
      questionIndex: nextIndex,
    };
  };

  const handleNavigate = (direction: "next" | "back") => {
    if (!questionResult || !filteredData.length) return;

    const navigationResult = handleFilteredNavigate(
      direction,
      filteredData,
      questionResult._id
    );

    dispatch(setQid(questionResult?._id));
    if (!navigationResult) return;

    // Update the question ID
    dispatch(setQid(navigationResult.questionId));

    // Update current question type (math or reading/writing module result)
    const questionKey = getModuleKey(
      navigationResult.type,
      navigationResult.moduleNumber
    );
    dispatch(setCurrentQuestion(questionKey));

    // Find the index of this question within its original module
    const moduleQuestions =
      completedTestData?.additionalProps?.overallresult?.[questionKey] || [];
    const questionIndexInModule = moduleQuestions.findIndex(
      (q: any) => q._id === navigationResult.questionId
    );

    // Update the question index
    dispatch(setCurrentQuestionIndex(questionIndexInModule));
  };

  const getModuleKey = (type: string, module: string) => {
    if (type === "rw") {
      return module === "Module1"
        ? "readingModuleOneOverallResult"
        : "readingModuleTwoOverallResult";
    } else {
      return module === "Module1"
        ? "mathsModuleOneOverallResult"
        : "mathsModuleTwoOverallResult";
    }
  };

  const getQuestionTitle = () => {
    if (questionResult?.sectionName === "ReadingAndWriting") {
      return `Reading And Writing : ${questionResult?.moduleName} - Question${questionResult?.qIdUi}`;
    } else {
      return `Maths : ${questionResult?.moduleName} - Question${questionResult?.qIdUi}`;
    }
  };

  

  return (
    <>
      {filteredData?.length ? (
        <>
          <div className="flex justify-between items-start mb-4">
            <h2 className="text-xl font-medium">
              {getQuestionTitle()}{" "}
              <span className="text-[#646464]">
                {!userAnswer && !userOption && "(Skipped)"}
              </span>
            </h2>
            <button
              onClick={() =>
                dispatch(setIsFullTestResultScreen(!isFullTestResultScreen))
              }
            >
              {isFullTestResultScreen ? (
                <HiArrowsPointingOut
                  size={24}
                  className="text-appPrimaryColor"
                />
              ) : (
                <HiArrowsPointingIn
                  size={24}
                  className="text-appPrimaryColor"
                />
              )}
            </button>
          </div>
          <div>
            <div className="text-gray-500">
              <div className="text-appPrimaryColor text-base">
                Time Taken: {time ?? "-"}
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <div className="text-appPrimaryColor font-semibold text-xs mb-[6px] uppercase">
                    QUESTION TYPE: {questionContentName ?? "-"}
                  </div>
                  <div className="text-[#9FC2FA] font-semibold text-xs uppercase">
                    QUESTION DOMAIN: {questionContentDomain ?? "-"}
                  </div>
                </div>
                <div className="flex items-center gap-x-6">
                  <button
                    disabled={currentIndex === 0}
                    onClick={() => handleNavigate("back")}
                    className={`${
                      currentIndex === 0
                        ? "bg-[#E1E1E1] pointer-events-none"
                        : "bg-appPrimaryColor hover:bg-white hover:text-[#327AEC] border border-[#327AEC]"
                    } rounded-xl w-12 h-12 flex items-center justify-center group`}
                  >
                    <MdKeyboardArrowLeft
                      size={24}
                      className={` ${
                        currentIndex === 0 ? "text-[#646464]" : "text-white"
                      } group-hover:text-[#327AEC]`}
                    />
                  </button>
                  <button
                    disabled={disableNext}
                    onClick={() => handleNavigate("next")}
                    className={`${
                      disableNext
                        ? "bg-[#E1E1E1] pointer-events-none text-[#646464]"
                        : "bg-appPrimaryColor hover:bg-white border border-[#327AEC]"
                    } rounded-xl w-12 h-12 flex items-center justify-center group`}
                  >
                    <MdKeyboardArrowRight
                      size={24}
                      className={` ${
                        disableNext ? "text-[#646464]" : "text-white"
                      } group-hover:text-[#327AEC]`}
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex w-full">
              <div
                className={`${
                  isFullTestResultScreen
                    ? "mb-32 pr-12 border-r-2 border-[#E1E1E1]"
                    : ""
                } ${isFullTestResultScreen ? "w-[60%]" : "w-full"}`}
              >
                <p>
                  {url && (
                    <img
                      src={url}
                      alt="desc"
                      className="mt-6 mb-3 mx-auto"
                    />
                  )}
                  {description !== "student produced response directions" ? (
                    <p className="leading-[21.6px] text-lg mt-6">
                      <RenderText
                        text={description}
                        imageDetails={questionResult}
                        isOptions={false}
                        questionId={questionResult?._id}
                      />
                    </p>
                  ) : (
                    <div>
                      <MathsModuleDirections />
                    </div>
                  )}
                </p>
                <p className="text-gray-800 my-6 text-lg leading-[21.6px]">
                  <RenderText
                    text={processedQuestion}
                    imageDetails={questionResult}
                    isOptions={false}
                    contentType="question"
                    questionId={questionResult?._id}
                  />
                  {answers?.length === 0 && (
                    <div className="mt-5">
                      {(userAnswer || userAnswer === exactAnswer) && (
                        <div className="flex items-center">
                          <p className="font-medium">Your Answer :</p>
                          <p
                            className={`ml-2 font-bold ${
                              isCorrect ? "text-green-700 " : "text-[#d50f00]"
                            }`}
                          >
                            {getAnswerText()}
                          </p>
                        </div>
                      )}
                      {exactAnswer !== userAnswer && (
                        <div className="flex items-center text-lg mt-5">
                          <p className="font-medium">
                            {!userAnswer && !userOption
                              ? "Answer"
                              : "Correct Answer"}{" "}
                            :
                          </p>
                          <p className="ml-2 text-[#0B6E4F] font-bold">
                            {exactAnswer}
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </p>
                <div
                  className={`space-y-2 text-lg ${
                    isFullTestResultScreen ? "w-full" : "min-w-64 w-fit"
                  }`}
                >
                  {answers?.map((answer: any, index: number) => (
                    <div
                      key={index}
                      className={`p-3 border-[3px] rounded-xl flex ${getAnswerBorderColor(
                        answer.answerOption
                      )}`}
                    >
                      <div className="flex-shrink-0">
                        <span
                          className={`font-medium mr-2 text-center w-8 h-8 flex justify-center items-center rounded-full ${getAnswerTextColor(
                            answer.answerOption
                          )} `}
                        >
                          {String.fromCharCode(65 + index)}
                        </span>
                      </div>
                      <p
                        className={`my-auto ${
                          answer.answerOption === exactAnswer
                            ? "text-[#0B6E4F]"
                            : answer.answerOption === userOption
                            ? "text-[#D00000]"
                            : "text-[#202020]"
                        }`}
                      >
                        <RenderText
                          text={answer?.answerPlaceHolder}
                          imageDetails={questionResult}
                          isOptions={true}
                          questionId={questionResult?._id}
                        />
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              {isFullTestResultScreen && (
                <>
                  <div className="w-[40%]">
                    <div className="bg-white rounded-xl pl-12 py-6 text-[#283044]">
                      <h3 className="text-xs text-appPrimaryColor uppercase mb-5 font-semibold">
                        Explanation
                      </h3>

                      <div className="text-lg">
                        <RenderText
                          text={questionResult?.explanation}
                          imageDetails={questionResult}
                          isOptions={false}
                          questionId={questionResult?._id}
                          contentType="explanation"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="text-gray-500 mb-2 text-lg">No results found</div>
          <div className="text-sm text-gray-400">
            Try adjusting your filters to see more results
          </div>
        </>
      )}
    </>
  );
};

export default QuestionContent;
