import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoLocationOutline } from "react-icons/io5";
import {
  setCount,
  setReviewPageClicked,
  StrikedOptionsData,
  setIsPopUp,
  setQuestionId,
} from "../../redux/Slices/startFullTestSlice";
import ReviewOnIcon from "../../assets/Images/ReviewOnButton.svg";
import { RootState } from "../../redux/Store/store";
import { getItem } from "../../utils/token";
import useSendTestActivity, {
  ActivityData,
} from "../customHook/useUserTestActivity";

interface Answers {
  qId: string;
  userAnswer: string | undefined;
  userOption: string;
}

interface Answer {
  isActive: boolean;
  answerOption: string;
  isCorrectAnswer: boolean;
  correctAnswer: string;
  answerPlaceHolder: string;
}

interface ReadingModuleData {
  _id: string;
  isActive: boolean;
  description: string;
  question: string;
  explanation: string;
  moduleId: string;
  sectionId: string;
  answers: Answer[];
  questionTypeId: string;
  more: any; // You might want to replace 'any' with a more specific type
  additionalProps: any;
}

interface QuestionOverView {
  questionsList: ReadingModuleData[];
  userAnswers: Answers[];
  count: number;
  navigation: boolean;
  heading: string;
  strikedOutOptions: StrikedOptionsData[];
  setStartTime: any;
  module: 301 | 302;
  section: 701 | 702;
  startTime: any;
}

const QuestionsOverView = ({
  questionsList,
  userAnswers,
  count,
  navigation,
  heading,
  strikedOutOptions,
  setStartTime,
  module = 301,
  section = 702,
  startTime,
}: QuestionOverView) => {
  const dispatch = useDispatch();
  const navigateCurrentQuestion = useCallback(
    (index: number) => {
      dispatch(setCount(index));
    },
    [dispatch]
  );
  const { reviewPageClicked,userTestId } = useSelector(
    (state: RootState) => state.root.startFullTest
  );
  const sendTestActivity = useSendTestActivity();
  const isPreview = getItem("testType") === "PREVIEW";

  const handleGotoReviewPage = () => {
    dispatch(setIsPopUp(false));
    dispatch(setReviewPageClicked(true));
    if (!isPreview) {
      const endTime = new Date();
      const activityData: ActivityData = {
        testId: userTestId,
        startTime,
        endTime,
        module,
        section,
        fromQuestion: count + 1,
        toQuestion: count + 1,
        event: 507,
        questionId: questionsList[count]._id,
      };
      dispatch(setQuestionId(questionsList[count]._id));
      sendTestActivity(activityData);
    }
  };
  return (
    <div className="overflow-auto scroll4">
      <div
        style={{
          boxShadow: navigation ? "none" : "0px 0px 10px 2px #00000026",
        }}
        className={`${
          navigation
            ? ""
            : "p-9 2xl:w-[45%] xl:[50%] lg:w-[60%] md:w-[70%] w-[90%] mx-auto rounded-xl mt-11 mb-4"
        }`}
      >
        <div
          className={`${
            navigation
              ? "border-y py-4 justify-center mx-7"
              : "border-b justify-between"
          } flex gap-8 font-normal pb-4 mb-3 border-[#a1a0a0]`}
        >
          {navigation ? (
            <div className="flex items-center gap-[10px] cursor-text text-sm">
              <IoLocationOutline size={20} />
              Current
            </div>
          ) : (
            <div className="font-araboto flex font-semibold items-center text-base">
              <span className="mr-1">{heading}</span>
            </div>
          )}
          <div className="flex gap-4">
            <div className="flex items-center gap-[10px]  cursor-text text-sm">
              <div className="w-4 h-4 mr-1 border border-dashed border-black"></div>
              Unanswered
            </div>
            <div className="flex items-center gap-[10px] cursor-text text-sm whitespace-nowrap">
              <img
                src={ReviewOnIcon}
                alt="review-icon"
                className="w-[18px] h-[18px]"
              />
              For Review
            </div>
          </div>
        </div>
        <div
          className={`flex flex-wrap ${
            navigation ? "px-6" : "px-0 gap-4"
          } pt-[15px]`}
        >
          {questionsList?.map((question, index) => {
            let matchedQuestion = userAnswers.find(
              (item) => item.qId === question["_id"]
            );
            let ismarkedForReview = strikedOutOptions?.find(
              (option) => option.questionId === question["_id"]
            )?.markedForReview;
            return (
              <div key={question._id}>
                <button
                  className={`${
                    navigation ? "w-8 h-8" : "w-10 h-10"
                  } font-araboto flex font-bold justify-center relative items-center border mx-2 my-3 ${
                    matchedQuestion?.userAnswer
                      ? "bg-primaryColor border text-white hover:bg-[#1a579c]"
                      : "border-dashed text-primaryColor hover:bg-lightGrey"
                  } border-black`}
                  onClick={() => {
                    dispatch(setReviewPageClicked(false));
                    dispatch(setIsPopUp(false));
                    navigateCurrentQuestion(index);
                  }}
                >
                  {ismarkedForReview && (
                    <div className="bg-white p-1 absolute -top-3 -right-3 rounded-md">
                      <img
                        alt="review-icon"
                        src={ReviewOnIcon}
                        className="w-3 h-3"
                      />
                    </div>
                  )}
                  <p
                    className={`${navigation ? "text-lgx" : "text-2xl"} ${
                      count === index && getItem("isFocused") ? "underline" : ""
                    }`}
                  >
                    {index + 1}
                  </p>
                  {count === index && navigation && (
                    <div className="flex justify-center absolute -top-[70%] text-black">
                      <IoLocationOutline size={20} />
                    </div>
                  )}
                </button>
              </div>
            );
          })}
        </div>
        {!reviewPageClicked && count !== questionsList.length && (
          <div className="mt-4 mb-6 flex items-center justify-center">
            <button
              className="flex justify-center px-6 py-2 font-inter bg-white text-primaryColor border border-primaryColor rounded-[49px] font-bold transition-[outline] hover:outline-2 hover:outline hover:outline-offset-[-2px]"
              onClick={handleGotoReviewPage}
            >
              Go to Review Page
            </button>
          </div>
        )}
      </div>
      <span className="absolute inner-block border-[25px] left-[46%] top-full border-x-transparent border-b-0 border-white"></span>
    </div>
  );
};

export default QuestionsOverView;
