import React from 'react'
import ParentDetails from '../../pages/LogInPage/ParentDetails';

const AddParentDetails = () => {
  return (
    <div className="px-3 py-6 gap-9 flex flex-col items-center">
      <h1 className="font-gully text-[40px] font-bold text-center text-appPrimaryColor">
        Add parent details
      </h1>
      <ParentDetails isDashboard />
    </div>
  );
}

export default AddParentDetails
