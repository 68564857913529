import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { startTestApi } from "../API/StartTest/startTestApi";

interface Answers{
    qId:string,
    userAnswer:string|undefined,
    userOption:string
}

interface ResultSlice{
    writingModuleOneAnswers:Answers[],
    writingModuleTwoAnswers:Answers[],
    mathsModuleOneAnswers:Answers[],
    mathsModuleTwoAnswers:Answers[],
    currentQuestion:string,
    currentQuestionIndex:number,
    filteredData:[]
}

const initialState: ResultSlice = {
  writingModuleOneAnswers: [],
  writingModuleTwoAnswers: [],
  mathsModuleOneAnswers: [],
  mathsModuleTwoAnswers: [],
  currentQuestion: "readingModuleOneOverallResult",
  currentQuestionIndex: 0,
  filteredData: [],
};

interface Payload {
    module: string;
    payload: Answers; // Changed from Answers[] to Answers

}


const resultSlice = createSlice({
  name: "resultsStore",
  initialState,
  reducers: {
    setAnswers: (state, action: PayloadAction<Payload>) => {
      const { module, payload } = action.payload;
      const answersArray = state[module as keyof ResultSlice] as Answers[];
      const indexOfExistingQuestion = answersArray.findIndex(
        (qn) => qn.qId === payload.qId
      );
      if (indexOfExistingQuestion !== -1) {
        answersArray[indexOfExistingQuestion].userAnswer = payload.userAnswer;
        answersArray[indexOfExistingQuestion].userOption = payload.userOption;
      } else {
        answersArray.push(payload);
      }
    },
    clearAnswers: (state) => {
      state.mathsModuleOneAnswers = [];
      state.mathsModuleTwoAnswers = [];
      state.writingModuleOneAnswers = [];
      state.writingModuleTwoAnswers = [];
    },
    retrieveReadingModuleOneAnswers: (state, action) => {
      state.writingModuleOneAnswers = action.payload;
    },
    retrieveReadingModuleTwoAnswers: (state, action) => {
      state.writingModuleTwoAnswers = action.payload;
    },
    retrieveMathsModuleOneAnswers: (state, action) => {
      state.mathsModuleOneAnswers = action.payload;
    },
    retrieveMathsModuleTwoAnswers: (state, action) => {
      state.mathsModuleTwoAnswers = action.payload;
    },
    setCurrentQuestion: (state, action) => {
      state.currentQuestion = action.payload;
    },
    setCurrentQuestionIndex: (state, action) => {
      state.currentQuestionIndex = action.payload;
    },
    setFilteredData: (state, action) => {
      state.filteredData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      startTestApi.endpoints.getUserResultsById.matchFulfilled,
      (state: any, action: any) => {
        state.currentQuestion = "readingModuleOneOverallResult";
        state.currentQuestionIndex = 0
      }
    );
  },
});
export const {
  setCurrentQuestion,
  setAnswers,
  clearAnswers,
  retrieveReadingModuleOneAnswers,
  retrieveReadingModuleTwoAnswers,
  retrieveMathsModuleOneAnswers,
  retrieveMathsModuleTwoAnswers,
  setCurrentQuestionIndex,
  setFilteredData
} = resultSlice.actions;
export default  resultSlice.reducer;