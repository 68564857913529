import { BiMailSend } from "react-icons/bi";
import { LuUser } from "react-icons/lu";
import checkIcon from "../../assets/Images/Check-Square--Streamline-Flex.svg";
import {
  useLazyResendEmailLinkQuery,
  useLazyForgetPasswordEmailQuery,
  useForgetPasswordSetterApiMutation,
} from "../../redux/API/Login/loginApi";
import { getItem } from "../../utils/token";
import { IoIosLock } from "react-icons/io";
import CustomTextField from "../../components/ReUsableComponents/CustomTextField";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../redux/Store/store";
import {
  setForgetPassEmail,
  setForgetPassEmailError,
  setForgetPasswordError,
  setForgetPasswordSetter,
  setForgetPasswordUserId,
} from "../../redux/Slices/loginSlice";
import { toast } from "react-toastify";
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { HiOutlineHome } from "react-icons/hi2";
import ErrorPage from "../../assets/Images/PageNotFound.svg";
import { useParams } from "react-router-dom";
import EmailVerificationPrompt, {
  EmailVerificationPromptType,
} from "./EmailVerificationPrompt";
import { setEmailVerifyId } from "../../redux/Slices/profileSetupSlice";
import { usePasswordValidation } from "../../utils";
import { GrCircleAlert } from "react-icons/gr";

const useRoutesConfig = () => {
  const [resendEmailLink,{isFetching,isLoading}] = useLazyResendEmailLinkQuery();
  const [forgetPasswordEmail] = useLazyForgetPasswordEmailQuery();
  const [forgetPasswordSetterApi] = useForgetPasswordSetterApiMutation();
  const { emailVerifyId } = useSelector(
    (state: RootState) => state.root.profileSetupSlice
  );

  const dispatch = useDispatch();
  const decodedId = atob(emailVerifyId ?? "")?.split("##")[1];
  const handleResendEmailLink = async () => {
    await resendEmailLink(decodedId ? decodedId : getItem("userId"));
  };

  const {
    forgetPassEmail,
    forgetPassEmailError,
    forgetPasswordSetter,
    forgetPassworrdUserId,
  } = useSelector((state: RootState) => ({
    forgetPassEmail: state.root.loginSlice.forgetPassEmail,
    forgetPassEmailError: state.root.loginSlice.forgetPassEmailError,
    forgetPasswordSetter: state.root.loginSlice.forgetPasswordSetter,
    forgetPassworrdUserId: state.root.loginSlice.forgetPassworrdUserId,
  }));

  const {  passwordError, validatePassword } = usePasswordValidation();

  const getForgetEmailDisbaled = () => {
    let userEmail = forgetPassEmail;
    const emailPattern = /^[a-zA-Z0-9][^\s@]*@[^\s@]+\.[a-zA-Z]+$/;
    if (userEmail.trim().length === 0) {
      return true;
    } else if (!emailPattern.test(userEmail)) {
      return true;
    }
    return false;
  };

  const getPasswordDisabled = () => {
    const userPassword = forgetPasswordSetter;
    if (userPassword.passwordOne.trim().length > 0 &&  userPassword.passwordTwo.trim().length > 0) {
      return false;
    }
    return true;
  };

  const handleForgotPasswordLink = async () => {
    let userEmail = forgetPassEmail;
    const emailPattern = /^[a-zA-Z0-9][^\s@]*@[^\s@]+\.[a-zA-Z]+$/;

    if (!emailPattern.test(userEmail)) {
      dispatch(setForgetPassEmailError(true));
      return;
    } else {
      dispatch(setForgetPassEmailError(false));
    }

    if (emailPattern.test(userEmail)) {
      try {
        const res = await forgetPasswordEmail(forgetPassEmail.trim()).unwrap();
        toast.success(res.message);
        window.open("/forgotPasswordEmail", "_self");
        dispatch(setForgetPassEmail(""))
      } catch (error: any) {
        toast.error(error.data?.message || "Something went wrong");
      }
    }
  };

  const handlePasswordSetter = async () => {
    const userPassword = forgetPasswordSetter;

    if (userPassword.passwordOne.trim() !== userPassword.passwordTwo.trim()) {
      dispatch(setForgetPasswordError("Passwords do not match. Please try again"));
    } else if (!validatePassword(userPassword.passwordOne.trim())) {
      dispatch(setForgetPasswordError("Password must be at least 8 characters long, contain at least one special character, and one uppercase letter."));
    } else {
      dispatch(setForgetPasswordError(""));
      try {
        const res = await forgetPasswordSetterApi({
          id: forgetPassworrdUserId,
          password: forgetPasswordSetter.passwordOne,
        }).unwrap();
        dispatch(setForgetPasswordSetter({
          passwordOne: "",
          passwordTwo: "",
        }))
        window.open("/passwordresetsuccess", "_self");
      } catch (error: any) {
        dispatch(setForgetPasswordError(error.data?.message ?? "There was an issue, please wait a moment and try again"));
        console.log(error, "error789");
      }
    }
  };

  return [
    {
      path: "/verifyemail/:id",
      title: " This Link Has Expired!",
      description: `It seems the link you tried to use has expired. If you need a new link,
        simply click the button below.`,
      btnLabel: "Re-Send Link",
      icon: <BiMailSend size={24} />,
      btnDisable: isFetching,
      onClickHandler: handleResendEmailLink,
    },
    {
      path: "/verifyemail",
      title: "You are almost done!",
      description:
        "Kindly visit your email inbox and click on the link we have sent to verify your email.",
      btnLabel: "Re-Send Link",
      icon: <BiMailSend size={24} />,
      btnDisable: isFetching,
      onClickHandler: handleResendEmailLink,
    },
    {
      path: "/emailVerified",
      title: "You’re done!",
      description:
        "Your email has been verified successfully! Click the below button to Sign in.",
      btnLabel: "Sign in",
      icon: <LuUser size={24} />,
      btnDisable: false,
      onClickHandler: () => {
        window.open("/login", "_self");
      },
      img: <img className="mb-9" src={checkIcon} alt="check" />,
    },
    {
      path: "/forgotPassword",
      title: "Forgot password? no problem!",
      description:
        "Enter your email below and we will send you a password reset link.",
      btnLabel: "Request Link",
      icon: <IoIosLock size={24} />,
      btnDisable: getForgetEmailDisbaled(),
      onClickHandler: handleForgotPasswordLink,
      additionalContent: <ForgetPassword />,
    },
    {
      path: "/forgotPasswordEmail",
      title: "You are almost done!",
      description:
        "Kindly visit your email inbox and click on the link we have sent to verify your email.",
      btnLabel: "",
      icon: <LuUser size={24} />,
      btnDisable: false,
      onClickHandler: () => {},
    },
    {
      path: "/passwordsetter/:userId",
      title: "Set Your New Password",
      description: "",
      btnLabel: "Reset Password",
      icon: <IoIosLock size={24} />,
      btnDisable: getPasswordDisabled(),
      onClickHandler: handlePasswordSetter,
      additionalContent: <PasswordSetter />,
    },
    {
      path: "/passwordresetsuccess",
      title: "You’re done!",
      description:
        "Your password has been reset successfully! Click the below button to Sign in.",
      btnLabel: "Sign in",
      icon: <LuUser size={24} />,
      btnDisable: false,
      onClickHandler: () => {
        window.open("/login", "_self");
      },
      additionalContent: <img className="mb-9" src={checkIcon} alt="check" />,
    },
    {
      path: "/linkexpired",
      title: "Seems like you got lost...",
      description: "",
      btnLabel: "Return To Home",
      icon: <HiOutlineHome size={24} />,
      btnDisable: false,
      onClickHandler: () => {
        window.open("/login", "_self");
      },
      additionalContent: <ErrorImage />,
    },
    // Add more route objects as needed
  ];
};

export default useRoutesConfig;

export const ForgetPassword = () => {
  const { forgetPassEmail, forgetPassEmailError } = useSelector(
    (state: RootState) => ({
      forgetPassEmail: state.root.loginSlice.forgetPassEmail,
      forgetPassEmailError: state.root.loginSlice.forgetPassEmailError,
    })
  );
  const dispatch = useDispatch();
  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setForgetPassEmail(e.target.value));
  };
  useEffect(() => {
    return(() => {dispatch(setForgetPassEmail(""))})
  },[])
  return (
    <>
      <CustomTextField
        onChangeHandler={handleEmail}
        placeholder="Email "
        type="text"
        name="userEmail"
        mandatory={true}
        value={forgetPassEmail}
        className="w-full !border-[#646464]"
        isError={forgetPassEmailError}
        containerClass="mb-3"
      />
    </>
  );
};

export const PasswordSetter = () => {
  const { forgetPasswordSetter, forgetPasswordError } = useSelector(
    (state: RootState) => ({
      forgetPasswordSetter: state.root.loginSlice.forgetPasswordSetter,
      forgetPasswordError: state.root.loginSlice.forgetPasswordError,
    })
  );
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState({
    passOne: false,
    passTwo: false,
  });
  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    dispatch(setForgetPasswordSetter({ name, value }));
  };
  useEffect(() => {
    return(() => {dispatch(setForgetPasswordSetter({
      passwordOne: "",
      passwordTwo: "",
    }))})
  },[])
  return (
    <div className="flex flex-col space-y-6 mb-6">
      {forgetPasswordError.length > 0 && (
        <div className="w-[450px]">
          <div className="flex items-center gap-x-1  py-3 px-5 mb-9 border border-[#d00000] rounded-xl">
            <div>
              <GrCircleAlert size={24} className="text-[#D00000] mr-[10px]" />
            </div>
            <p className="text-[#D00000] text-base font-semibold">
              {forgetPasswordError}
            </p>
          </div>
        </div>
      )}
      <div className="flex justify-between relative">
        <CustomTextField
          onChangeHandler={handlePassword}
          placeholder="Enter New Password"
          type={`${showPass.passOne ? "text" : "password"}`}
          name="passwordOne"
          mandatory={true}
          value={forgetPasswordSetter.passwordOne}
          className="w-full !border-[#646464]"
        />
        {forgetPasswordSetter.passwordOne && (
          <button
            className="absolute right-6 bottom-2 cursor-pointer text-2xl text-[#646464]"
            onClick={() =>
              setShowPass((prev) => ({ ...prev, passOne: !showPass.passOne }))
            }
          >
            {showPass.passOne ? <MdOutlineRemoveRedEye /> : <RiEyeCloseLine />}
          </button>
        )}
      </div>
      <div className="flex justify-between relative">
        <CustomTextField
          onChangeHandler={handlePassword}
          placeholder="Enter New Password"
          type={`${showPass.passTwo ? "text" : "password"}`}
          name="passwordTwo"
          mandatory={true}
          value={forgetPasswordSetter.passwordTwo}
          className="w-full !border-[#646464]"
          isError={forgetPasswordError.length > 0}
        />
        {forgetPasswordSetter.passwordTwo && (
          <button
            className={`absolute ${
              forgetPasswordError ? "right-12" : "right-6"
            } bottom-2 cursor-pointer text-2xl text-[#646464]`}
            onClick={() =>
              setShowPass((prev) => ({ ...prev, passTwo: !showPass.passTwo }))
            }
          >
            {showPass.passTwo ? <MdOutlineRemoveRedEye /> : <RiEyeCloseLine />}
          </button>
        )}
      </div>
    </div>
  );
};

export const ErrorImage = () => {
  return (
    <div className="w-[300px] h-[300px]">
      <img src={ErrorPage} alt="error-icon" />
    </div>
  );
};

export const ParamsWrapper = (props: EmailVerificationPromptType) => {
  const { userId } = useParams();
  const { id } = useParams();
  const dispatch = useDispatch();

  if (userId) {
    dispatch(setForgetPasswordUserId(userId));
  }
  if (id) {
    dispatch(setEmailVerifyId(id));
  }
  return (
    <EmailVerificationPrompt {...props}>
      {props.additionalContent}
    </EmailVerificationPrompt>
  );
};