export const QuestionMenuDescription = () => (
    <>
      <p>
        At the bottom of each testing screen, you'll find a menu that allows
        you to navigate to any question in the section. You'll see which
        questions you've answered, which you haven't, and any that you've
        marked for review.
      </p>
    </>
  );
    
    export default QuestionMenuDescription;