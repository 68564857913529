import { useNavigate } from "react-router-dom";
import testCompletion from "../../assets/Images/testCompletion.svg";
import CustomButton from "../ReUsableComponents/CustomButton";
import { LuHome } from "react-icons/lu";
import testBg from "../../assets/Images/test-complete-background.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectFullTestState } from "../../redux/Selectors/selectorsUse";

import { getItem } from "../../utils/token";
import macBook from "../../assets/Images/Macbook.svg";
import { setIsTestInstructionsOpen, setIsTestStarted } from "../../redux/Slices/startFullTestSlice";

const TestCompleted = () => {
  const navigate = useNavigate();
  const { startTestSlice } = useSelector(selectFullTestState);
  const dispatch = useDispatch();
  const isPreview = getItem("testType") === "PREVIEW";
  const { testId } = startTestSlice;

  return (
    <>
      <div
        style={{ backgroundImage: `url(${testBg})` }}
        className="flex h-screen items-center flex-col z-50"
      >
        <div className="h-11 flex justify-end items-center gap-x-[10px] text-right px-[168px] py-[14px] bg-white w-full cursor-pointer" onClick={() =>{
                dispatch(setIsTestInstructionsOpen(false));
            dispatch(setIsTestStarted(false));
          }}>
          <button className="text-[13px] font-semibold" >
            Return to Home
          </button>
          <LuHome size={20} />
        </div>
        <p className="text-[32px] mb-11 mt-[75px] -z-10">
          You’re All Finished!
        </p>

        {isPreview ? (
          <div
            className="flex flex-col items-center justify-center leading-6 p-8 w-[448px] rounded-md text-sm"
            style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
          >
            <img src={macBook} alt="macbook" />
            <p className="text-center">
              Return to the homepage to start the preview again.
            </p>
            <p className="text-center mb-4">
              Reminder: there are no scores of feedback in this preview.
            </p>
            <p className="mb-6 min-w-11 text-center">
              When you see this page on test day, you'll know your scores have
              been submitted.
            </p>
            <CustomButton
              name="Return to Home"
              onClickHandler={() => {
                dispatch(setIsTestStarted(false));
                dispatch(setIsTestInstructionsOpen(false));
              }}
              bg="bg-vibrantYellow"
              color="black"
              border="border-black"
              text="text-sm"
              round="[49px]"
              hover="transition-[outline] hover:outline-2 hover:outline hover:outline-offset-[-2px]"
            />
          </div>
        ) : (
          <>
            <img
              className="w-1/3 mb-5 -z-10"
              src={testCompletion}
              alt="test completion"
            />
            <CustomButton
              name="View Your Score"
              onClickHandler={() => navigate(`/practices/results/${testId}`)}
              bg="bg-vibrantYellow"
              color="black"
              border="border-black"
              text="text-sm"
              round="[49px]"
              hover="transition-[outline] hover:outline-2 hover:outline hover:outline-offset-[-2px]"
            />
          </>
        )}
      </div>
    </>
  );
};

export default TestCompleted;
