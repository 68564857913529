import {combineReducers} from '@reduxjs/toolkit'
import counterReducer from '../Slices/counterSlice'
import dialogSlice from '../Slices/dialogSlice'
import startFullTestSlice from '../Slices/startFullTestSlice'
import resultSlice from '../Slices/resultSlice';
import loaderSlice from '../Slices/loaderSlice';
import loginSlice from '../Slices/loginSlice';
import pricingSlice from '../Slices/pricingSlice';
import questionBankSlice from '../Slices/questionBankSlice';
import profileSetupSlice from '../Slices/profileSetupSlice';

const rootReducer = combineReducers({
    counter:counterReducer,
    dialog:dialogSlice,
    startFullTest:startFullTestSlice,
    resultsSlice:resultSlice,
    loaderSlice:loaderSlice,
    loginSlice:loginSlice,
    pricingSlice:pricingSlice,
    questionBankSlice:questionBankSlice,
    profileSetupSlice:profileSetupSlice
})

export default rootReducer