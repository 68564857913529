import  { useEffect, useId, useState } from 'react';
import TeX from "@matejmazur/react-katex";
import { HightLightText, HightLightTextData } from '../../redux/Slices/startFullTestSlice';

const cleanInputString = (input:any) => {
  return input
    ?.replace(/[\u200B-\u200D\uFEFF]/g, "")
    ?.replace(/\s+/g, " ")
    ?.trim();
};
const extractLatexParts = (cleanedString:any) => {
  const latexPattern = /\$\~\s*(.*?)\s*\$\~/g;
  const parts = [];
  let lastIndex = 0;
  let match;

  while ((match = latexPattern.exec(cleanedString)) !== null) {
    if (match.index > lastIndex) {
      parts.push(cleanedString.slice(lastIndex, match.index));
    }
    parts.push(<TeX key={crypto.randomUUID()}>{match[1]}</TeX>);
    lastIndex = latexPattern.lastIndex;
  }

  if (lastIndex < cleanedString?.length) {
    parts.push(cleanedString.slice(lastIndex));
  }

  return parts;
};

const processImageParts = async (part:any, imageDetails:any, isOptions:any) => {
  const imgPattern = !isOptions ? /\{#(.*?)#\}/g : /\{(.*?)\}/g;
  const processedParts = [];
  let lastImgIndex = 0;
  let imgMatch;

  // Check if imageDetails is empty
  if (Object.keys(imageDetails).length === 0) {
    // If empty, return the part as is
    return [part];
  }

  while (
    (imgMatch = imgPattern.exec(part)) !== null &&
    Object.keys(imageDetails)?.length > 0
  ) {
    if (imgMatch.index > lastImgIndex) {
      processedParts.push(part.slice(lastImgIndex, imgMatch.index));
    }
    const imgSrc = imageDetails?.[imgMatch[1]];
    processedParts.unshift(
      imgSrc ? (
        <img className="mb-2" key={crypto.randomUUID()} src={imgSrc} alt="" />
      ) : (
        `{#${imgMatch[1]}#}`
      )
    );
    lastImgIndex = imgPattern.lastIndex;
  }

  if (lastImgIndex < part.length) {
    processedParts.push(part.slice(lastImgIndex));
  }

  return processedParts;
};
const processPlaceholders = (subParts:any) => {
  return subParts.map((subPart:any) =>
    typeof subPart === 'string' ? subPart.split(':exp{}:exp').map((strPart, strIndex) =>
      strIndex > 0 ? [<span key={crypto.randomUUID()}>________</span>, strPart] : strPart
    ).flat() : subPart
  ).flat();
};
const processString = (inputString:any, imageDetails:any, isOptions:boolean,questionData:any,questionId:string) => {
  const cleanedString = cleanInputString(inputString);
  const parts = extractLatexParts(cleanedString);

  return Promise.all(
    parts.map(async (part) => {
      // Use async here
      if (typeof part === "string") {
        const processedParts = await processImageParts(
          part,
          imageDetails,
          isOptions
        ); // Await the promise
        return processPlaceholders(processedParts);
      }
      return part;
    })
  ).then((results) => results.flat());
};

const getHighlightData = (
  highlightedContent: HightLightTextData[],
  questionId: string,
  contentType: string
) => {
  return highlightedContent
    ?.find((data) => data.questionId === questionId)
    ?.hightLightText.filter(
      (highlight) => highlight.contentType === contentType
    )
    .sort((a, b) => a.startIndex - b.startIndex);
};

const customColors: Record<string, string> = {
  lightBlue: "bg-lightBlue",
  lightYellow: "bg-lightYellow",
  lightPink: "bg-lightPink",
};

const focusedColors: Record<string, string> = {
  lightBlue: "bg-[#0099ff]",
  lightYellow: "bg-[#fffb00]",
  lightPink: "bg-[#ff00ff]",
};

const preprocessData = (data: HightLightText[]): HightLightText[] => {
  return data.reduce<HightLightText[]>((acc, current, index) => {
    const parentUnderline =
      index > 0 && acc[index - 1].startIndex + acc[index - 1].selectedText.length > current.startIndex
        ? acc[index - 1].underline
        : "none";

    const effectiveUnderline =
      current.underline !== "none" ? current.underline : parentUnderline;

    acc.push({ ...current, underline: effectiveUnderline as any});
    return acc;
  }, []);
};

const renderNestedData = (
  data: HightLightText[], 
  handleHighlightedTextClick: (
    highlightText: string,
    startIndex: number,
    e: React.MouseEvent
  ) => void
) => {
  const sortedData = preprocessData(data).sort((a, b) => a.startIndex - b.startIndex);
  
  const merge = (index: number): (string | JSX.Element) => {
    
    if (index >= sortedData.length) {
      return <></>;
    }
   
    const currentItem = sortedData[index];
    const nextItem = sortedData[index + 1];
    
    if (nextItem && nextItem.startIndex < currentItem.startIndex + currentItem.selectedText.length) {
      
      const overlapStart = currentItem.startIndex + currentItem.selectedText.length - nextItem.startIndex;
      const beforeOverlap = currentItem.selectedText.slice(0, currentItem.selectedText.length - overlapStart);
      const afterOverlap = currentItem.selectedText.slice(beforeOverlap.length + nextItem.selectedText.length);
      const mergedContent = (
        <>
          <span
            key={`start-${currentItem.startIndex}`}
            className={`${customColors[currentItem.bgColor]} focus:outline-none focus:${focusedColors[currentItem.bgColor]} cursor-auto inline`}
            style={{
              textDecorationLine: currentItem.underline !== "none" ? "underline" : "none",
              textDecorationStyle: currentItem.underline !== "none" ? (currentItem.underline as any) : undefined,
            }}
            onClick={(evt) => handleHighlightedTextClick(currentItem.selectedText, currentItem.startIndex, evt)}
          >
            {beforeOverlap}
          </span>
          {merge(index + 1)}
          <span
            key={`end-${currentItem.startIndex}`}
            className={`${customColors[currentItem.bgColor]} focus:outline-none focus:${focusedColors[currentItem.bgColor]} cursor-auto inline`}
            style={{
              textDecorationLine: currentItem.underline !== "none" ? "underline" : "none",
              textDecorationStyle: currentItem.underline !== "none" ? (currentItem.underline as any) : undefined,
            }}
            onClick={(evt) => handleHighlightedTextClick(currentItem.selectedText, currentItem.startIndex, evt)}
          >
            {afterOverlap}
          </span>
        </>
      );

      return mergedContent;
    }
    
    return (
      <span
        key={`highlight-${currentItem.startIndex}`}
        className={`${customColors[currentItem.bgColor]} focus:outline-none focus:${focusedColors[currentItem.bgColor]} cursor-auto inline`}
        style={{
          textDecorationLine: currentItem.underline !== "none" ? "underline" : "none",
          textDecorationStyle: currentItem.underline !== "none" ? (currentItem.underline as any) : undefined,
        }}
        onClick={(evt) => handleHighlightedTextClick(currentItem.selectedText, currentItem.startIndex, evt)}
      >
        {currentItem.selectedText}
      </span>
    );
  };

  return merge(0);
};

const renderHighlightedText = (
  textContent: string[],
  highlightedContent: HightLightTextData[],
  questionId: string,
  contentType: string,
  handleHighlightedTextClick: (
    highlightText: string,
    startIndex: number,
    e: React.MouseEvent
  ) => void,
  highlightId:any
): (string | JSX.Element)[] => {
  const elements: (string | JSX.Element)[] = [];
  const highlightData = getHighlightData(
    highlightedContent,
    questionId,
    contentType
  );

  if (!highlightData) {
    return textContent.map((text) => <span key={highlightId}>{text}</span>);
  }

  textContent.forEach((text) => {
    let currentIndex = 0;
    const sortedHighlights = [...highlightData].sort(
      (a, b) => a.startIndex - b.startIndex
    );
    let skipNextHighlight: boolean[] = [];

    sortedHighlights.forEach((highlight, index) => {
      if (skipNextHighlight.some((value) => value)) {
        return;
      }

      const { startIndex, selectedText, bgColor } = highlight;

      if (startIndex > currentIndex) {
        elements.push(
          <span key={`text-before-${startIndex}`}>
            {text?.slice?.(currentIndex, startIndex)}
          </span>
        );
      }

      let endIndex = startIndex + selectedText.length;

      const nestedHighlights = sortedHighlights.filter(
        (innerHighlight, innerIndex) =>
          innerIndex > index &&
          innerHighlight.startIndex < endIndex &&
          innerHighlight.startIndex + innerHighlight.selectedText.length >
            startIndex
      );

      if (nestedHighlights.length > 0) {
        let lastElementIndex =
          nestedHighlights[nestedHighlights.length - 1].startIndex +
          nestedHighlights[nestedHighlights.length - 1].selectedText.length;
        endIndex = Math.max(endIndex, lastElementIndex);
        skipNextHighlight = [];
        nestedHighlights.forEach(() => skipNextHighlight.push(true));

        let nestedHighlightedData = renderNestedData(
          [highlight, ...nestedHighlights],
          handleHighlightedTextClick
        );
        elements.push(
          <span key={`nested-${startIndex}`}>{nestedHighlightedData}</span>
        );
      } else {
        elements.push(
          <span
            role="button"
            key={`highlight-${startIndex}`}
            className={`${customColors[bgColor]} focus:outline-none focus:${focusedColors[bgColor]} cursor-auto inline`}
            style={{
              textDecorationLine:
                highlight.underline !== "none" ? "underline" : "none",
              textDecorationStyle:
                highlight.underline !== "none"
                  ? (highlight.underline as any)
                  : undefined,
            }}
            tabIndex={0}
            onClick={(evt) =>
              handleHighlightedTextClick(selectedText, startIndex, evt)
            }
          >
            {selectedText}
          </span>
        );
      }

      currentIndex = endIndex;
    });

    if (currentIndex < text.length) {
      elements.push(
        <span key={`text-after-${currentIndex}`}>
          {text.slice(currentIndex)}
        </span>
      );
    }
  });

  return elements;
};

const RenderText = ({
  text,
  imageDetails = {},
  isOptions,
  contentType,
  highlightedContent,
  questionId,
  handleHighlightedTextClick,
}: any) => {
  const [processedText, setProcessedText] = useState<JSX.Element | null>(null);
  const highlightId = useId();

  useEffect(() => {
    const processText = async () => {
      let result = await processString(
        text,
        imageDetails.imageDetails || {},
        isOptions,
        imageDetails,
        questionId
      );
      if (contentType === "question" || contentType === "description") {
        result = await renderHighlightedText(
          result,
          highlightedContent,
          questionId,
          contentType,
          handleHighlightedTextClick,
          highlightId
        );
      }
      setProcessedText(<>{result}</>);
    };
    processText();
  }, [questionId]);

  return <div>{processedText || "Loading..."}</div>;
};

export default RenderText;
