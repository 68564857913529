import MathTable from "../../assets/Images/math_instructions.png";

import { Dialog } from "@headlessui/react";

interface ModalProps {
  module: string;
  onCloseHandler: () => void;
  dialogOpen: boolean;
}

const DirectionModal = ({ module, onCloseHandler, dialogOpen }: ModalProps) => {
  const renderContent = (moduleName: string) => {
    if (
      moduleName === "writingModuleOneAnswers" ||
      moduleName === "writingModuleTwoAnswers"
    ) {
      return (
          <div className="flex flex-col justify-center items-center  pt-5 w-[49.875rem] text-base minion-pro gap-2.5 ">
            <p>
              The questions in this section address a number Of important
              reading and writing skills. Each question includes one or more
              passages. which may include a table or graph. Read each passage
              and question carefully. and then choose the best answer to the
              question based on the passage(s).
            </p>
            <p>
              All questions in this section are multiple-choice with four answer
              choices. Each question has a single best answer.
            </p>
          </div>
      );
    }

    if (
      moduleName === "mathsModuleOneAnswers" ||
      moduleName === "mathsModuleTwoAnswers"
    ) {
      return (
        <div className="flex flex-col overflow-y-auto scroll4 h-[50rem]">
        <div className="flex flex-col leading-6  mx-8 py-6 text-lg whitespace-pre-line opacity-80">
          <p>
            The questions in this section address a number of important math
            skills.
          </p>
          <p className="py-3">
            Use of a calculator is permitted for all questions. A reference sheet,
            calculator, and these directions can be accessed throughout the test.
          </p>
          <p>Unless otherwise indicated:</p>
          <p className="pl-16">
            <ul>
              <li> All variables and expressions represent real numbers.</li>
              <li> Figures provided are drawn to scale.</li>
              <li> All figures lie in a plane.</li>
              <li>
                The domain of a given function f is the set of all real numbers
                for which is a real number.
              </li>
            </ul>
          </p>
          <p className="py-3">
            For <span className="font-semibold">multiple-choice questions</span>,
            solve each problem and choose the correct answer from the choices
            provided, Each multiple-choice question has a single correct answer.
          </p>
          <p>
           <span> For&nbsp;</span>
            <span className="font-semibold">
              student-produced response questions
            </span>
            <span>, solve each problem and enter your answer as described below.</span>
          </p>
          <p className="pl-16">
            <ul>
              <li>
                {" "}
                If you find more than one correct answer, enter only one answer.
              </li>
              <li>
                You can enter up to 5 characters for a positive answer and up to 6
                characters (including the negative sign) for a negative answer.
              </li>
              <li>
                If your answer is a fraction that doesn't fit in the provided
                space, enter the decimal equivalent.
              </li>
              <li>
                If your answer is a decimal that doesn't fit in the provided
                space, enter it by truncating or rounding at the fourth digit.
              </li>
              <li>
                If your answer is a mixed number (such as 3[1/2]), enter it as an
                improper fraction (7/2) or its decimal equivalent (3.5).
              </li>
              <li>
                Don't enter symbols such as a percent sign, comma, or dollar sign.
              </li>
            </ul>
          </p>
          <div className="w-auto">
            <img src={MathTable} alt="mathTable" />
          </div>
        </div>

      </div>
      );
    }
  };

  return (
<Dialog
  open={dialogOpen}
  as="div"
  className="relative z-10 focus:outline-none"
  onClose={onCloseHandler}
>
  {/* Gray overlay background */}
  {dialogOpen && (
    <div className="fixed inset-0 bg-gray-500 mt-[78px] opacity-50 z-0"></div>
  )}
  
  <div className="fixed top-16 left-5 z-10 overflow-y-auto">
    <div className="flex min-h-full items-center justify-center p-4 ">
      <Dialog.Panel className="flex flex-col w-[53.875rem] h-[37rem] max-w-4xl border bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 relative z-10">
        {/* <div className="absolute -top-2 left-7 w-0 h-0 border-l-[10px] border-r-[10px] border-b-[10px] border-transparent border-b-white"></div> */}
        <div className="absolute -top-4 left-6 w-0 h-0 border-l-[30px] border-r-[30px] border-b-[30px] border-transparent border-b-white"></div>

        {renderContent(module)}
        
        <div className="flex h-[4.5rem] mt-auto ml-auto py:20 pl-10 ">
          <button
            className="bg-yellow-300 hover:border-2 hover:border-black text-black border border-black font-bold py-3 px-5 mt-auto rounded-3xl ml-auto "
            onClick={onCloseHandler}
          >
            Close
          </button>
        </div>
      </Dialog.Panel>
    </div>
  </div>
</Dialog>

  );
};

export default DirectionModal;
