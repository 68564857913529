import CustomButton from "../ReUsableComponents/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../redux/Slices/dialogSlice";
import { selectFullTestState } from "../../redux/Selectors/selectorsUse";
import {
  useLazyResumeTestQuery,
  useSaveAndExitTestMutation,
} from "../../redux/API/StartTest/startTestApi";
import Loader from "../ReUsableComponents/Loader";
import { getItem, setItem } from "../../utils/token";
import {
  setHighlightedContent,
  setIsHighlightNotes,
  setIsTestInstructionsOpen,
  setIsTestStarted,
  setReviewPageClicked,
} from "../../redux/Slices/startFullTestSlice";
import useSendTestActivity, { ActivityData } from "../customHook/useUserTestActivity";
import { IoCloseOutline } from "react-icons/io5";

const SaveAndExit = () => {
  const dispatch = useDispatch();
  const { startTestSlice, resultSlice, loadingSlice } =
    useSelector(selectFullTestState);
  const [saveAndExitTest] = useSaveAndExitTestMutation({});
  const isPreview = getItem("testType") === "PREVIEW";
  const sendTestActivity = useSendTestActivity();
  const [resumeTest] = useLazyResumeTestQuery();
  const {questionId} = startTestSlice
  
  const continueTest = () => {
    dispatch(closeDialog());
  };

  const exitTest = async () => {    
    dispatch(closeDialog());
    dispatch(setIsTestStarted(false));
    dispatch(setIsTestInstructionsOpen(false));
    dispatch(setIsHighlightNotes(false));
    dispatch(setHighlightedContent([]));
  };

  const saveandExitTest = async () => {
    let savePayload = {
      userTestId: startTestSlice.userTestId,
      testId: startTestSlice.testId,
      questionData: null,
      answerData: {
        Mathematics: {
          Module1: resultSlice.mathsModuleOneAnswers,
          Module2: resultSlice.mathsModuleTwoAnswers,
        },
        ReadingAndWriting: {
          Module1: resultSlice.writingModuleOneAnswers,
          Module2: resultSlice.writingModuleTwoAnswers,
        },
      },
      additionalProps: {
        currentModuleName: startTestSlice.currentModuleName,
        count: startTestSlice.count,
        currentTime: startTestSlice.currentTime,
        strikedAndMarkedData: startTestSlice.strikedOptionsData,
        highlightedContent: startTestSlice.highlightedContent,
        reviewPageClicked: startTestSlice.reviewPageClicked,
      },
      testCompleted: false,
      overallTestResult: startTestSlice.overallresult,
    };
    const endTime = new Date();
    const activityData: ActivityData = {
      testId: startTestSlice.userTestId,
      startTime: startTestSlice.startTime
        ? startTestSlice.startTime
        : new Date(),
      endTime,
      module: startTestSlice.activeModule,
      section: startTestSlice.activeSection,
      fromQuestion: startTestSlice.count + 1,
      toQuestion: startTestSlice.count + 1,
      event: 503,
      questionId: questionId,
    };
    sendTestActivity(activityData);
    await saveAndExitTest(savePayload);
    setItem("testData", JSON.stringify(savePayload));
    resumeTest({});
    dispatch(closeDialog());
    dispatch(setIsTestStarted(false));
    dispatch(setIsTestInstructionsOpen(false));
    dispatch(setIsHighlightNotes(false));
    dispatch(setHighlightedContent([]));
    dispatch(setReviewPageClicked(false));
    //dispatch(clearAnswers())
  };

  return (
    <div className="flex flex-col">
      <div className="text-[#202020]">
        <div className="flex justify-between items-center mb-5">
          <p className="text-2xl">
            {isPreview
              ? "Are You Sure You Want to Exit the Preview?"
              : "Do You Want to Exit this Practice Test?"}
          </p>
          <button onClick={() => dispatch(closeDialog())}>
            <IoCloseOutline
              className="hover:bg-gray-200 rounded-md"
              size={24}
            />
          </button>
        </div>
        <p className="text-base">
          {isPreview
            ? "Test previews are not timed or scored and your progress isn't saved. If you exit and come back, you'll start over."
            : "If you exit now, we'll save your progress and you can resume this practice test any time."}
        </p>
      </div>
      {loadingSlice.isLoading ? (
        <div className="flex justify-center ">
          <Loader />
        </div>
      ) : (
        <div className="flex justify-end mt-8 items-center">
          <CustomButton
            name={
              isPreview ? "Continue Test Preview" : "Continue Practice Test"
            }
            onClickHandler={continueTest}
            bg="bg-white"
            font="font-semibold"
            mx="mx-2"
            color="primaryColor"
            text="text-sm"
            hover="hover:underline"
          />
          <CustomButton
            name={isPreview ? "Exit" : "Save and Exit"}
            onClickHandler={isPreview ? exitTest : saveandExitTest}
            bg="bg-[#FEDB00]"
            font="font-semibold"
            color="black"
            border="border border-[#272727]"
            round="full"
            text="text-sm"
            py="py-2 px-6"
            hover="transition-[outline] hover:outline-2 hover:outline hover:outline-offset-[-2px]"
          />
        </div>
      )}
    </div>
  );
};

export default SaveAndExit;
