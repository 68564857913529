import { useEffect } from 'react';
import CustomButton from '../ReUsableComponents/CustomButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/Store/store';
import { isDummyRender, setIsTestInstructionsOpen, setIsTestStarted } from '../../redux/Slices/startFullTestSlice';
import { LuClock3,LuBookOpenCheck,LuPersonStanding } from "react-icons/lu";
import { PiLockOpen } from "react-icons/pi";
import { getItem, removeItem, setItem } from '../../utils/token';
import FullTest from '../../pages/FullTest';
import { next } from '../../utils/commonLogic';


const TestInstructions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isPreview = getItem("testType") === "PREVIEW";
  const {isTestStarted} = useSelector((state:RootState) => state.root.startFullTest);

  useEffect(() => {
    if (!isPreview) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        const message = "Are you sure you want to reload?";
        event.returnValue = message;

        return message;
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [navigate]);

  useEffect(() => {
      const isReloaded = getItem("reloadFlag");

      if (isReloaded) {
        navigate("/dashboard");
        removeItem("reloadFlag");
      }

      window.onbeforeunload = () => {
        setItem("reloadFlag", "true");
      };
    

    return () => {
      window.onbeforeunload = null;
    };
  }, [navigate]);

  const handleNext = () => {
    dispatch(isDummyRender(true));
    dispatch(setIsTestStarted(true));
  };

  const handleBack = () => {
    if (!isPreview) {
    if (window.confirm('Are you sure you want to go back?')) {
        navigate('/dashboard');
        dispatch(setIsTestStarted(false));
        dispatch(setIsTestInstructionsOpen(false));
      }
    }
  };

  return (
    <>
    {isTestStarted ? <FullTest /> : (
      <div className="flex w-full overflow-y-auto pt-[40px] scroll4 flex-col justify-between items-center" style={{height:"calc(100vh - 79px)" }}>
        <div className="flex w-[456px] flex-col items-center gap-10">
          <p className="text-center font-inter text-2xl font-normal">
            Practice Test
          </p>
          <div className="flex p-10 flex-col items-center gap-10 self-stretch rounded-xl shadow-xl">
            <div className="flex justify-center items-start gap-[10px] self-stretch">
              <div className="flex p-1  items-center gap-[10px] rounded-full bg-[#D9D9D9]">
                <LuClock3 className="text-[#444444] w-6 h-6" />
              </div>
              <div className="flex flex-col items-start gap-[10px]">
                <p className="text-center font-inter text-xl font-semibold">
                  Timing
                </p>
                <p className="font-inter text-xs font-light">
                  Practice tests are timed, but you can pause them. To continue
                  on another device, you have to start over. We delete incomplete
                  practice tests after 90 days.
                </p>
              </div>
            </div>
            <div className="flex justify-center items-start gap-[10px] self-stretch">
              <div className="flex p-1  items-center gap-[10px] rounded-full bg-[#D9D9D9]">
                <LuBookOpenCheck className="text-[#444444] w-6 h-6" />
              </div>
              <div className="flex flex-col items-start gap-[10px]">
                <p className="text-center font-inter text-xl font-semibold">
                  Scores
                </p>
                <p className="font-inter text-xs font-light">
                  When you finish practice test, go to{" "}
                  <span className="font-bold">My Practice</span> to see your
                  scored and get personalized study tips.
                </p>
              </div>
            </div>
            <div className="flex justify-center items-start gap-[10px] self-stretch">
              <div className="flex p-1  items-center gap-[10px] rounded-full bg-[#D9D9D9]">
                <LuPersonStanding className="text-[#444444] w-6 h-6" />
              </div>
              <div className="flex flex-col items-start gap-[10px]">
                <p className="text-center font-inter text-xl font-semibold">
                  Assistive Technology (AT)
                </p>
                <p className="font-inter text-xs font-light">
                  Be sure to practice with any AT you use for testing. If you
                  configure your AT settings here, you may need to repeat this
                  step on test day.
                </p>
              </div>
            </div>
            <div className="flex justify-center items-start gap-[10px] self-stretch">
              <div className="flex p-1  items-center gap-[10px] rounded-full bg-[#D9D9D9]">
                <PiLockOpen className="text-[#444444] w-6 h-6" />
              </div>
              <div className="flex flex-col items-start gap-[10px]">
                <p className="text-center font-inter text-xl font-semibold">
                  No Device Lock
                </p>
                <p className="font-inter text-xs font-light">
                  We dont lock your device during practice. On test day, you'll
                  be blocked from using other programs or apps.
                </p>
              </div>
            </div>
          </div>
        </div>
        <footer className="fixed bottom-0 flex w-full h-[80px] px-10 py-[10px] justify-end items-center gap-[10px] bg-white border border-darkGrey">
          <CustomButton
            name="Back"
            onClickHandler={handleBack}
            font="font-bold"
            width="text-sm"
            py="py-2 px-5"
            round="full"
          />
          <CustomButton
            name="Next"
            onClickHandler={handleNext}
            font="font-bold"
            text="text-sm"
            py="py-2 px-5"
            round="full"
          />
        </footer>
        </div>
      )}
    </>
  );
};

export default TestInstructions;
